import {
    Box,
    Button,
    RadioGroup,
    FormGroup,
    Grid,
    Slider,
    Typography,
    TextField,
    InputAdornment
} from "@mui/material";
import Info from '@mui/icons-material/Info'
import { useState } from 'react'
import FilterRadioButton from './FilterRadioButton';
import FilterCheckBox from "./FilterCheckBox";

import styles from './styles';
import { fiveRatings, petWeightPolicies, hotelAmenities } from './listData';

const guestRatings = [
    { label: 'Any', value: 'any'},  
    { label: 'Wonderful 9+', value: '9'},
    { label: 'Very good 8+', value: '8'},
    { label: 'Good 7+', value: '7'},
]

const Filters = ({
    search,
    handlePetNumberChange,
    showInfoBox,
    setShowInfoBox,
    petWeights,
    handleWeightChange,
    setAllowsCats,
    allowsCats,
    setHasNoPetFees,
    hasNoPetFees,
    filterAmenities,
    handleAmenityChange,
    sliderValue,
    handleSliderChange,
    setShouldFilter,
    shouldFilter,
    valuetext,
    minPrice,
    maxPrice,
    handleHotelRatingChange,
    hotelRating,
    handleRatingChange,
    rating,
    setHasLowPetFees,
    hasLowPetFees
}) => {

    const [showAllFilters, setShowAllFilters] = useState(false)
    const showCount = 3

    return (
        <>
            <Box 
                sx={{...styles.filterContainer, mt: '1rem'}}
            >
                <Typography variant="h6" mb="0rem" pb="0">Filter by</Typography>
                <Box sx={styles.filterContainer} style={{ marginTop: '1rem'}}>
                    <Typography sx={styles.filterHeader}>Popular filters</Typography>
                    <FormGroup onChange={handleAmenityChange}>
                        {hotelAmenities.filter(item => {
                            const popularFilters = ['pool', 'restaurant', 'free_parking', 'breakfastIncluded', 'spa'  ]
                        
                            return popularFilters.some(thing => thing == item.id)
                        }).map((item, index) => (
                        <FilterCheckBox 
                            key={index}
                            checked={filterAmenities[item.id]}
                            inputName={item.id}
                            label={item.label}
                        />
                        ))}
                    </FormGroup>
                </Box>

                <Typography sx={styles.filterHeader}>Number of pets</Typography>
                <Grid container columnSpacing={{ xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }} sx={{ width: '100%' }}>
                    <Grid item md={6} lg={6} xl={6}>
                        <Button
                            onClick={() => handlePetNumberChange(1)} 
                            variant={search?.occupants?.dogs == 1 ? 'contained' : 'outlined'} 
                            color="primary"
                            sx={{ width: '100%' }}
                        >
                            1
                        </Button>
                    </Grid>
                    <Grid item md={6} lg={6} xl={6}>
                        <Button 
                            onClick={() => handlePetNumberChange(2)} 
                            variant={search?.occupants?.dogs == 2 ? 'contained' : 'outlined'} 
                            color="primary"
                            sx={{ width: '100%' }}
                        >
                            2
                        </Button>
                    </Grid>
                    <Grid item md={6} lg={6} xl={6}>
                        <Button 
                            onClick={() => handlePetNumberChange(3)} 
                            variant={search?.occupants?.dogs == 3 ? 'contained' : 'outlined'} 
                            color="primary"
                            sx={{ width: '100%' }}
                        >
                            3
                        </Button>
                    </Grid>
                    <Grid item md={6} lg={6} xl={6}>
                        <Button 
                            onClick={() => handlePetNumberChange(4)} 
                            variant={search?.occupants?.dogs == 4 ? 'contained' : 'outlined'} 
                            color="primary"
                            sx={{ width: '100%' }}
                        >
                            4+
                        </Button>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={styles.filterContainer}>
                <Box 
                    sx={{
                    display: "flex",
                    gap: "0.5rem",
                    flexDirection: "row",
                    alignItems: "center",
                    }}
                >
                    <Typography sx={styles.filterHeader}>
                    Pet policy
                    </Typography>
                    <Info
                        onMouseOver={() => setShowInfoBox(true)}
                        onMouseLeave={() => setShowInfoBox(false)}
                        onClick={() => setShowInfoBox(!showInfoBox)}
                        fontSize="small"
                    /> 
                    {showInfoBox &&
                        <Box position="relative">
                            <Box 
                            sx={{
                                position: "absolute",
                                zIndex: "20",
                                backgroundColor: "white",
                                left: "0",
                                boxShadow: "1",
                                p: "0.5rem",
                                width: "280px",
                            }}
                            >
                            <Typography variant="base">
                                Select the size of the pet that you 
                                are traveling with. If you are 
                                traveling with multiple pets, select 
                                the size of your largest pet.
                            </Typography>
                            </Box>
                        </Box>
                    }
                </Box>

                <RadioGroup defaultValue="all" value={petWeights} onChange={handleWeightChange}>
                    {petWeightPolicies.map((item, index) => (
                        <FilterRadioButton
                            key={index}
                            value={item.value}
                            label={item.label}
                        />
                    ))}
                </RadioGroup>
            </Box>

            <Box sx={styles.filterContainer}>
                <Typography sx={styles.filterHeader}>Features</Typography>
                <FormGroup onChange={() => setAllowsCats(!allowsCats)}>
                    <FilterCheckBox
                        label="Accepted cats"
                        inputName="25"
                        checked={allowsCats}
                    />
                </FormGroup>

                <FormGroup onChange={() => setHasNoPetFees(!hasNoPetFees)} sx={{ my: 0 }}>
                    <FilterCheckBox
                        label="$0 pet fees"
                        inputName="25"
                        checked={hasNoPetFees}
                    />
                </FormGroup>

                <FormGroup onChange={() => setHasLowPetFees(!hasLowPetFees)} sx={{ my: 0 }}>
                    <FilterCheckBox
                        label="Low Pet Fee ($0 - $50)"
                        inputName="low-pet-fees"
                        checked={hasLowPetFees}
                    />
                </FormGroup>
            </Box>
            
            <Box sx={styles.filterContainer}>
                <Typography sx={styles.filterHeader}>Amenities</Typography>
                <FormGroup onChange={handleAmenityChange}>
                    {hotelAmenities.slice(0, (showAllFilters ? 9999 : showCount)).map((item, index) => (
                    <FilterCheckBox 
                        key={index}
                        checked={filterAmenities[item.id]}
                        inputName={item.id}
                        label={item.label}
                    />
                    ))}
                    {!showAllFilters && <Typography onClick={() => setShowAllFilters(true)} color="primary" cursor="pointer" sx={{ '&:hover' : { textDecoration: 'underline'} }}>See more</Typography>}
                    {showAllFilters && <Typography onClick={() => setShowAllFilters(false)} color="primary" cursor="pointer" sx={{ '&:hover' : { textDecoration: 'underline'} }}>See less</Typography>}
                </FormGroup>
            </Box>

            <Box sx={styles.filterContainer}>
                <Typography sx={styles.filterHeader} fontWeight="600">Total price</Typography>
                <Box display="flex" gap="0.75rem" width="92%">
                    <Box mt="0.5rem" pt="0.5rem" pl="0.5rem" borderRadius="12px" border='1px solid black'>
                        <TextField
                          variant="standard"
                          label="Min" 
                          value={sliderValue[0] || 0} 
                          size="small"
                          onChange={(e) => {
                            e.preventDefault()
                            console.log(e)
                            handleSliderChange(
                                {
                                    target: {
                                        value: [parseFloat(e.target?.value || 0), sliderValue[1]]
                                    }
                                }
                            )
                            setShouldFilter(!shouldFilter)
                          }} 
                          InputProps={{
                                    disableUnderline: true,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 56 56"><path fill="currentColor" fillRule="evenodd" d="M29 30v10c3.519-.316 5-2.287 5-4.89c0-2.507-1.152-3.99-5-5.11m-3-5v-9c-3.273.415-5 2.33-5 4.43c0 2.1 1.364 3.647 5 4.57m2.84.737l1.072.277C35.784 27.423 39 29.917 39 34.836c0 5.658-4.466 8.868-10.16 9.284V48h-2.523v-3.88c-5.672-.439-10.16-3.741-10.317-9.284h4.622c.402 2.702 2.1 4.688 5.695 5.08V29.849l-.916-.231c-5.672-1.363-8.731-3.996-8.731-8.684c0-5.173 4.02-8.591 9.647-9.03V8h2.523v3.903c5.582.462 9.624 3.926 9.803 9.169h-4.645c-.29-2.91-2.3-4.596-5.158-4.966z"/></svg>
                                      </InputAdornment>
                                    ),
                                  }}
                        />
                    </Box>
                    <Box mt="0.5rem" pt="0.5rem" pl="0.5rem" borderRadius="12px" border='1px solid black'>
                        <TextField
                          variant="standard"
                          label="Max" 
                          InputProps={{
                                    disableUnderline: true,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 56 56"><path fill="currentColor" fillRule="evenodd" d="M29 30v10c3.519-.316 5-2.287 5-4.89c0-2.507-1.152-3.99-5-5.11m-3-5v-9c-3.273.415-5 2.33-5 4.43c0 2.1 1.364 3.647 5 4.57m2.84.737l1.072.277C35.784 27.423 39 29.917 39 34.836c0 5.658-4.466 8.868-10.16 9.284V48h-2.523v-3.88c-5.672-.439-10.16-3.741-10.317-9.284h4.622c.402 2.702 2.1 4.688 5.695 5.08V29.849l-.916-.231c-5.672-1.363-8.731-3.996-8.731-8.684c0-5.173 4.02-8.591 9.647-9.03V8h2.523v3.903c5.582.462 9.624 3.926 9.803 9.169h-4.645c-.29-2.91-2.3-4.596-5.158-4.966z"/></svg>
                                      </InputAdornment>
                                    ),
                                  }}
                          value={sliderValue[1]} 
                          onChange={(e) => {
                            e.preventDefault()
                            handleSliderChange(
                                {
                                    target: {
                                        value: [parseFloat(sliderValue[0]), parseFloat(e.target?.value || 0)]
                                    }
                                }
                            )
                            setShouldFilter(!shouldFilter)
                          }} 
                        />
                    </Box>
                </Box>
                <Slider
                    getAriaLabel={() => 'Price range'}
                    value={sliderValue}
                    onChange={handleSliderChange}
                    onChangeCommitted={() => setShouldFilter(!shouldFilter)}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    min={minPrice}
                    step={1}
                    max={maxPrice}
                    // marks={[
                    // {
                    //     value: sliderValue[0],
                    //     label: `$${sliderValue[0]}`
                    // },
                    // {
                    //     value: sliderValue[1],
                    //     label: `$${sliderValue[1]}`
                    // }
                    // ]}
                    sx={{ mx: '1em', width: '85%', maxWidth: '260px' }}
                />
            </Box>
                  
            <Box sx={styles.filterContainer}>
                <Typography sx={styles.filterHeader}>Hotel rating</Typography>
                <Box display="flex" flexWrap="wrap" gap="0.5rem">
                    {fiveRatings.map(i => (
                        <Box onClick={() => {
                            handleHotelRatingChange({
                                target: {
                                    name: i,
                                    checked: !hotelRating[i]
                                }
                            })
                        }} key={i} 
                            borderRadius="12px" 
                            maxWidth="40px"
                            px="1rem"
                            py="0.75rem"
                            sx={{ 
                                backgroundColor: hotelRating[i] ? '#03b8c0' : 'white',
                                border: hotelRating[i] ? '2px solid black' : '1px solid gray',
                            }} 
                        >
                            <Box display="flex" alignItems="center" gap="0.35rem" justifyContent="center">
                                {i} <Box fontSize="18px" lineHeight="0px"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="m5.825 21l1.625-7.025L2 9.25l7.2-.625L12 2l2.8 6.625l7.2.625l-5.45 4.725L18.175 21L12 17.275z"/></svg></Box>
                            </Box>
                        </Box>
                    // <FilterCheckBox
                    //     key={i}
                    //     checked={hotelRating[i]}
                    //     inputName={i}
                    //     label={i}
                    // />
                    ))}
                </Box>
            </Box>

            <Box sx={styles.filterContainer}>
                <Typography sx={styles.filterHeader}>Guest rating</Typography>
                <RadioGroup defaultValue="any" value={rating} onChange={handleRatingChange}>
                    {guestRatings.map((item, index) => (
                        <FilterRadioButton
                            key={index}
                            value={item.value}
                            label={item.label}
                        />
                    ))}
                </RadioGroup>
            </Box>
        </>
    )
}

export default Filters