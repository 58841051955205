import { Grid, Box, Typography, Link, Button } from "@mui/material";
import React, { lazy, Suspense } from 'react';
import { useNavigate } from "react-router-dom";
const ImageSlider = lazy(() => import('./ImageSlider'));
const HotelTags = lazy(() => import('./HotelTags'));
import StarIcon from "@mui/icons-material/Star";
 

const stateCodes = {
    "AL": "Alabama",
    "AK": "Alaska",
    "AS": "American Samoa",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "DC": "District Of Columbia",
    "FM": "Federated States Of Micronesia",
    "FL": "Florida",
    "GA": "Georgia",
    "GU": "Guam",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MH": "Marshall Islands",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "MP": "Northern Mariana Islands",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PW": "Palau",
    "PA": "Pennsylvania",
    "PR": "Puerto Rico",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VI": "Virgin Islands",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming"
}

const ListingCardSquare = ({
  id,
  travolutionaryId,
  images,
  name,
  fullAddressLine,
  starRating,
  hotelRating,
  numberOfReviews,
  cancellation = false,
  lowestTotalPriceAfterTax,
  currency = "$",
  showAmenities = true,
  highlighted = false,
  showPrice = true,
  petFee,
  petAllowance,
  petFeeValue,
  petSize,
  alias,
  state,
  city,
  displayNew = false,
  ...props
}) => {
  const navigate = useNavigate();
  const mobileCardPadding = 1;


  const PriceDetails = () => (
    <Box textAlign="right" mr="0.5rem">
      <Typography variant="base" sx={{ fontSize: '0.75rem'}}>rates from</Typography>
      <Typography variant="h6">
        {currency}{Math.round(lowestTotalPriceAfterTax)} <span style={{ fontSize: '1rem', fontWeight: 400}}>/ night</span>
      </Typography>
    </Box>
  )

  function slugify(str) {
    if (!str) {
      return ''
    }
    // Convert all non-word characters to hyphens
    str = str.replace(/[^\w-]/g, '-');

    // Remove all consecutive hyphens
    str = str.replace(/-+/g, '-');

    // Trim leading and trailing hyphens
    str = str.trim('-');

    return str.toLowerCase();
  }

  const hotelUrl = `/pet-friendly-hotel/${slugify(stateCodes[state])}/${slugify(city)}/${alias}`
  let starRatingFormat = hotelRating
  if (hotelRating?.toString().length === 1) {
    starRatingFormat = `${hotelRating}.0`
  }

  const hotelRatingText = (rating) => {
    const float = parseFloat(rating);
    if (float >= 9.1) {
      return 'Exceptional'
    } else if (float >= 8.5) {
      return 'Excellent'
    } else if (float >= 8.0) {
      return 'Very Good'
    } else if (float >= 7) {
      return 'Good'
    } else {
      return 'Guest Rating'
    }
  }

  const ratingText = hotelRatingText(starRating)

  if (displayNew) {
      return (
        <>
          {highlighted && <Box sx={{ borderTop: "1px solid #ddd" }} />}
          <Box
            sx={{
              color: "text.primary",
              display: "flex",
              flexDirection: "column",
              flex: 1,
              height: "100%",
              maxWidth: "100%",
              borderRadius: 3,
              justifyContent: "center",
              alignItems: "center",
              background: "#fff",
              transition: "all .25s ease-in-out",
              paddingBottom: "0px",
              "&:hover": { boxShadow: 7 },
              my: highlighted ? 4 : 0,
              boxShadow: 5,
            }}
            // {...props}
          >
      <Suspense fallback={<div>Loading Image Slider...</div>}>
            <ImageSlider
              images={images}
              name={name}
              forceLarge={true}
              small={true}
              height={'200px'}
            />
    </Suspense>
            <Box
              onClick={() => (window.location.href = hotelUrl)}
              sx={{
                cursor: "pointer",
                px: { xs: mobileCardPadding, sm: 0 },
                pb: { xs: mobileCardPadding, sm: "0" },
                width: "100%",
                display: "flex",
                flex: 1,
              }}
            >
              <Grid
                container
                sx={{
                  minHeight: 120,
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: 'space-between',
                    mt: '0.5rem',
                    ml: '0.3rem',
                    px: '0.2rem',
                    pb: '0.25rem'

                  }}
                >
                  <Box sx={{ display: 'flex', width: '100%', overflow: 'auto',  minHeight: '60px', alignItems: 'center'}}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#222",
                        whiteSpace: 'normal',
                        fontSize: '16px',
                        textAlign: 'left',
                        overflowX: 'hidden',
                        mb: '0.5rem',
                      
                      }}
                    >
                      {name}
                    </Typography>
                    <Box onClick={() => navigate(`${hotelUrl}#reviews`)} sx={{ ml: 'auto', mt: '0.25rem', mr: '0.5rem', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '0.25rem' }}>
                      <Box sx={{ color: 'black'}}><StarIcon fontSize="inherit" /></Box>
                      <Typography variant="base" mb="2px">{starRatingFormat}</Typography>
                    </Box> 
                  </Box>

           
                
                  <Box mb="0.5rem" sx={{
                    flex: 1,
                    minHeight: '100px'
                  }}>
                     <Suspense fallback={<div>Loading Hotel Tags...</div>}>
                    <HotelTags
                      pet_fee={petFee}
                      pet_allowance={petAllowance}
                      pet_fee_value={petFeeValue}
                      pet_size={petSize}
                    />
                    </Suspense>
                  </Box>
             
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'flex-end',
                      width: 1,
                      marginTop: 'auto',
                      mb: '0.25rem'
                    }}
                  >
                    <Box sx={{  textAlign: 'left', display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                      {parseInt(lowestTotalPriceAfterTax) > 0 ?

                      <Box>
                        <Typography variant="base" sx={{ fontSize: '0.75rem'}}>rates from</Typography>
                        <Typography variant="h6">
                          {currency}{Math.round(lowestTotalPriceAfterTax)} <span style={{ fontSize: '1rem', fontWeight: 400}}>/ night</span>
                        </Typography>
                      </Box> : <Box minHeight="56px"></Box>
                      }
                    </Box>

                 
                    <Box mr="0.5rem" mb="0.5rem">
                      <Button variant="contained">Book Now</Button>
                    </Box>
          
                  </Box>
                  

                 
                </Grid>
              </Grid>
            </Box>
          </Box>
          {highlighted && <Box sx={{ borderTop: "1px solid #ddd" }} />}
        </>
      );
  }

  return (
    <>
      {highlighted && <Box sx={{ borderTop: "1px solid #ddd" }} />}
      <Box
        sx={{
          color: "text.primary",
          display: "flex",
          flexDirection: "column",
          flex: 1,
          height: "100%",
          maxWidth: "100%",
          borderRadius: 3,
          justifyContent: "center",
          alignItems: "center",
          background: "#fff",
          transition: "all .25s ease-in-out",
          paddingBottom: "0px",
          "&:hover": { boxShadow: 7 },
          my: highlighted ? 4 : 0,
          boxShadow: 5,
        }}
        // {...props}
      >
  <Suspense fallback={<div>Loading Image Slider...</div>}>
        <ImageSlider
          images={images}
          name={name}
          forceLarge={true}
          small={displayNew}
          height={displayNew ? '200px' : '301px'}
        />
</Suspense>
        <Box
          onClick={() => (window.location.href = hotelUrl)}
          sx={{
            cursor: "pointer",
            px: { xs: mobileCardPadding, sm: 0 },
            pb: { xs: mobileCardPadding, sm: "0" },
            width: "100%",
            display: "flex",
            flex: 1,
          }}
        >
          <Grid
            container
            sx={{
              minHeight: 120,
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: 'space-between',
                mt: '1rem',
                ml: '0.3rem',
                px: '0.2rem',
                pb: '0.25rem'

              }}
            >
              <Box sx={{ display: 'flex', width: '100%', overflow: 'auto' }}>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#222",
                    whiteSpace: 'normal',
                    textOverflow: "ellipsis",
                    textWrap: 'nowrap',
                    textAlign: 'left',
                    fontSize: '1.20rem',
                    overflowX: 'hidden'
                  }}
                >
                  {name?.slice(0, displayNew ? 26 : 35)}{`${(displayNew ? name?.length > 26 : name?.length > 35) ? '...' : ''}`}
                </Typography>
                <Box onClick={() => navigate(`${hotelUrl}#reviews`)} sx={{ ml: 'auto', mt: '0.25rem', mr: '0.5rem', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '0.25rem' }}>
                  <Box sx={{ color: 'black'}}><StarIcon fontSize="inherit" /></Box>
                  <Typography variant="base" mb="2px">{starRatingFormat}</Typography>
                </Box> 
              </Box>

              <Typography
                mb="0.25rem"
                variant="base"
                sx={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  color: "#717171",
                  textAlign: 'left'
                }}
              >
                {city}, {state}
              </Typography>
            
              <Box mb="0.5rem" sx={{
                flex: 1,
                minHeight: '100px'
              }}>
                 <Suspense fallback={<div>Loading Hotel Tags...</div>}>
                <HotelTags
                  pet_fee={petFee}
                  pet_allowance={petAllowance}
                  pet_fee_value={petFeeValue}
                  pet_size={petSize}
                />
                </Suspense>
              </Box>
         
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  width: 1,
                  marginTop: 'auto',
                  mb: '0.25rem'
                }}
              >
                <Box sx={{  textAlign: 'left', display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                  <Box sx={{ color: 'white', borderRadius: '12px', p: '0.5rem', backgroundColor: ratingText === 'Guest Rating' ? '#D3D3D3' : '#03989E', }}>
                    {starRating?.toFixed(1)} 
                  </Box>
                  <Box>
                    <span style={{ fontSize: '14px', fontWeight: 800, marginBottom: 0, paddingBottom: 0 }}><b>{ratingText}</b></span>
                    <Link
                      href={`${hotelUrl}#reviews`}
                      sx={{
                        mt: 0,
                        pt: 0,
                        color: "#666",
                        textDecoration: "underline",
                        ml: 0.25,
                        fontWeight: 500,
                        opacity: 0.75,
                        fontSize: "12px",
                        lineHeight: 0
                      }}
                    >
                      <Typography lineHeight="1rem" mt="0" pt="0" fontSize="12px">{numberOfReviews ? numberOfReviews: 'see'} reviews</Typography>
                    </Link>
                  </Box>
                </Box>

                <Box>
                  <PriceDetails />
                </Box>
      
              </Box>
              

             
            </Grid>
          </Grid>
        </Box>
      </Box>
      {highlighted && <Box sx={{ borderTop: "1px solid #ddd" }} />}
    </>
  );
};

export default ListingCardSquare;
