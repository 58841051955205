import {Box, Dialog, DialogContent, useTheme, DialogTitle, useMediaQuery, IconButton} from "@mui/material";
import React, { FC, useState } from "react";
import { CSSObject } from "@mui/material";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

interface Props {
  sx?: CSSObject;
}

const ResetPasswordCard: FC<Props> = ({ sx }) => {
  const navigate = useNavigate()
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const userId = urlParams.get('userId');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const [step, setStep] = useState(1);
  const [showOkModal, setShowOkModal] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
 
  const [intervalTimer, setIntervalTimer] = useState(null)
  const [timer, setTimer] = useState(null)

  const onSubmitEmail = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!email) {
      // TODO: show error message for email input field
      return
    }

    setShowOkModal(true);
    setTimer(300)

    const interval = setInterval(() => {
      setTimer((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        } else {
          clearInterval(intervalTimer);
          setIntervalTimer(null)
          return 0;
        }
      });
    }, 1000)
    setIntervalTimer(interval)

    await fetch(process.env.REACT_APP_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: `
          query checkUserForResetInput(
            $email: String!
          ){
            checkUserForReset(input: { email: $email }) {
              status
              message
            }
          }
        `,
        variables: {
          email: email
        }
      })
    })
  }

  const onResetPassword = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setShowOkModal(true);


    await fetch(process.env.REACT_APP_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: `
          mutation ResetUserPasswordInput(
            $userId: String!
            $newPassword: String!
          ){
            resetUserPassword(input: { userId: $userId, newPassword: $newPassword }) {
              status
              message
            }
          }
        `,
        variables: {
          userId: userId,
          newPassword: password
        }
      })
    })
  }

  React.useEffect(() => {
    if (token && userId) {
      setStep(2);
    }
    ValidatorForm.addValidationRule("isPasswordMatch", (value: string) => {
      if (value !== password) {
        return false;
      }
      return true;
    });

    return () => {
      ValidatorForm.removeValidationRule("isPasswordMatch");
    };
  });

  const handleClose = () => {
    setShowOkModal(false);
  };

  return (
    <Box>
        {step === 1 && (
          <ValidatorForm>
            <TextValidator
              sx={{ mt: '1rem' }}
              fullWidth={true}
              name="email"
              label="Email Address"
              variant="outlined"
              value={email}
              validators={["required", "isEmail"]}
              errorMessages={["This field is required", "Email is not valid"]}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setEmail(e.currentTarget.value);
              }}
              FormHelperTextProps={{}}
            />
            <Box
              sx={{
                mt: 2,
                textAlign: "center",
              }}
            >
              <Button
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                onClick={onSubmitEmail}
                disabled={intervalTimer}
              >
                Reset Password
              </Button>
              {intervalTimer && <div style={{ color: 'gray', marginTop: '0.5rem'}}>Re-send email ({timer})</div>}
            </Box>
          </ValidatorForm>
        )}
        {step === 2 && (
          <ValidatorForm
            onSubmit={(e: React.SyntheticEvent) => {
              e.preventDefault();
            }}
          >
            <TextValidator
              fullWidth={true}
              name="password"
              label="New Password"
              variant="outlined"
              type="password"
              value={password}
              validators={["required"]}
              errorMessages={["This field is required"]}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setPassword(e.currentTarget.value);
              }}
              FormHelperTextProps={{}}
            />
            <TextValidator
              fullWidth={true}
              name="password"
              label="Confirm Password"
              variant="outlined"
              type="password"
              value={confirmPassword}
              validators={["required", "isPasswordMatch"]}
              errorMessages={["This field is required", "password mismatch"]}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setConfirmPassword(e.currentTarget.value);
              }}
              FormHelperTextProps={{}}
              sx={{
                mt: 1,
              }}
            />
            <Box
              sx={{
                mt: 1,
                textAlign: "center",
              }}
            >
              <Button
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                onClick={onResetPassword}
              >
                Update Password
              </Button>
            </Box>
          </ValidatorForm>
        )}
      <Dialog
        open={showOkModal}
        keepMounted
        fullWidth
        fullScreen={fullScreen}
        maxWidth={"sm"}
        onClose={handleClose}
        scroll="body"
        aria-labelledby="amenities-dialog-slide-title"
        aria-describedby="amenities-dialog-slide-description"
      >
        <DialogTitle
          id="amenities-dialog-slide-title"
          sx={{
            textAlign: "center",
          }}
        >
         
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            px: {
              xs: 1,
              sm: 2.5,
            },
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" sx={{ mt: 5 }}>
           {(token && userId) ? 'Password Successfully Reset! Please login with your new password.' : 'Please check your email for a reset link.'}
          </Typography>
          <br />
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="button"
            onClick={() => {setShowOkModal(false); if (token) navigate('/login')}}
            sx={
              {
                mt: 2,
              }
            }
          >
            Ok
          </Button>
        </DialogContent>
      </Dialog>
    </ Box>
  );
};

export default ResetPasswordCard;
