import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  useMediaQuery,
  useTheme,
  Select,
  Typography,
  Chip,
  Divider,
  Input
} from "@mui/material";
import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import { LargeFilterBar } from '../../components/LargeFilterBar';
import CardList from "../../components/CardList";
import Map from "../../components/UI/Map";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import FilterBar from "../../components/MobileHomePageFilterBar/MobileHomePageFilterBar";
import Loader from "../../components/UI/Loader";
import { Dispatch } from "redux";
import { saveSearch } from "../../store/searchReducer";
import { setList } from "../../store/hotelListReducer";
import { useNavigate } from "react-router-dom";
import WhitePawsIcon from '../../assets/icon/white-paws.png';
import {
  Edit,
  Close as CloseIcon
} from '@mui/icons-material'
import useHotelsQuery from "../../hooks/UseHotelsQuery";
import bannerIconTennis from '../../assets/public/sections-icons/banner-icon-tennis.png';
import Filters from "./Filters";
import ErrorDog from "../../components/UI/ErrorDog";
import { stateCodes } from '../../components/ListingCard/ListingCard'
import { ADS_LOCATION_SEARCH } from '../../constants/adsSearchLocations'

const styles = {
  sidebarSection: { 
    my: '3rem',
    width: '100%'
  }
}

const ListingPageNew = ({ ...props }) => {
  const initialAmenityFilterState = {
    pool: false,
    airportShuttle: false,
    parking: false,
    spa: false,
    kitchen: false,
    wifiIncluded: false,
    restaurant: false,
    gym: false,
    cribs: false,
    washerAndDryer: false,
    dryCleaning: false,
    wheelchairAccessible: false,
    smokeFree: false,
    hourFrontDesk: false,
    ATMMachine: false,
    attractionShuttleService: false,
    businessCenter: false,
    carRental: false,
    coffeeMaker: false,
    hairDryer: false,
    iceMachine: false,
    inRoomSafe: false,
    concierge: false,
    breakfastIncluded: false,
    free_parking: false,
  }
  const amenityTitle = {
    pool: 'Pool',
    airportShuttle: 'Airport shuttle',
    parking: 'Parking',
    spa: 'Spa',
    kitchen: 'Kitchen',
    wifiIncluded: 'WiFi included',
    restaurant: 'Restaurant',
    gym: 'Gym',
    cribs: 'Cribs',
    washerAndDryer: 'Washer and dryer',
    dryCleaning: 'Dry cleaning',
    wheelchairAccessible: 'Wheelchair accessible',
    smokeFree: 'Smoke-free property',
    hourFrontDesk: '24 hour front desk',
    ATMMachine: 'ATM machine',
    attractionShuttleService: 'Attraction Shuttle Service',
    businessCenter: 'Business Center',
    carRental: 'Car Rental',
    coffeeMaker: 'Coffee Maker',
    hairDryer: 'Hair Dryer',
    iceMachine: 'Ice Machine',
    inRoomSafe: 'In Room Safe',
    concierge: 'Concierge',
    free_parking: 'Free Parking',
    breakfastIncluded: 'Breakfast Included'
  }

  const PET_LABEL_WEIGHT = {
    '25': '1-25 lbs',
    '50': '26-75 lbs',
    '75': '75+ lbs'
  }
  function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();


  const params = new URLSearchParams(window.location.search);
  const location = params.get('loc');
  const LOCATION_PARAM_MAP = {
    sd: ADS_LOCATION_SEARCH.SAN_DIEGO,
    sf: ADS_LOCATION_SEARCH.SAN_FRANCISCO,
    seattle: ADS_LOCATION_SEARCH.SEATTLE,
    denver: ADS_LOCATION_SEARCH.DENVER,
    la: ADS_LOCATION_SEARCH.LOS_ANGELES,
    austin: ADS_LOCATION_SEARCH.AUSTIN,
    chicago: ADS_LOCATION_SEARCH.CHICAGO,
    ny: ADS_LOCATION_SEARCH.NEW_YORK,
    portland: ADS_LOCATION_SEARCH.PORTLAND,
    santafe: ADS_LOCATION_SEARCH.SANTA_FE
  }


  // const [sessionId, setSessionId] = useState('')
  const [formatHotels, setFormatHotels] = useState([]);
  const search = useSelector((state: any) => state.searchReducer.search);
  
  console.log(search)
  if (location && search.fromAd !== location) {
    const today = new Date();
    const twoWeeksAway = new Date();
    const nextDay = new Date();
    twoWeeksAway.setDate(today.getDate() + 14);
    nextDay.setDate(today.getDate() + 15);

    dispatch(
      saveSearch({
        city: LOCATION_PARAM_MAP[location]?.city,
        checkIn: twoWeeksAway.toISOString(),
        checkOut: nextDay.toISOString(),
        occupants: { adults: 2, children: 0, dogs: 1 },
        lat: LOCATION_PARAM_MAP[location]?.lat,
        lng: LOCATION_PARAM_MAP[location]?.lng,
        fromAd: location
      })
    );    
  }


  const [markers, setMarkers] = useState([]);
  const [sort, setSort] = useState('featured');
  const [selectedCity, setSelectedCity] = useState(search.city)
  
  const [hotelRating, setHotelRating] = useState({'0': false, '1': false, '2': false, '3': false, '4': false, '5': false});
  const [rating, setRating] = useState('any');
  const [filterAmenities, setFilterAmenities] = useState(initialAmenityFilterState)
  const [petWeights, setPetWeights] = useState(null)  
  const [allowsCats, setAllowsCats] = useState(false)
  const [hasNoPetFees, setHasNoPetFees] = useState(false)
  const [hasLowPetFees, setHasLowPetFees] = useState(false)

  const [query, setQuery] = useState('');
  const [sliderValue, setSliderValue] = useState([0, 1000])
  const [shouldFilter, setShouldFilter] = useState(false)
  const [openMap, setOpenMap] = useState(false)
  const [viewFilters, setViewFilters] = useState(false);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000);
  const [timer, setTimer] = useState(null)

  const [showInfoBox, setShowInfoBox] = useState(false)
  const [showSearchBar, setShowSearchBar] = useState(false)

  const [displayHotels, setDisplayHotels] = useState([]);
  const [displayLoader, setDisplayLoader] = useState(true);
  const {hotels, loading, loadingMore, sessionId} = useHotelsQuery({
    search: search,
    maxWaitInSeconds: 3,
    childrenAge: search?.occupants?.children > 0 ? search?.occupants?.childrenAge : []
  })

  console.log(sessionId)
  
  //TODO: doesn't handle number of pets currently MOBILE
  const [previousFilterState, setPreviousFilterState] = useState({
    query,
    sliderValue,
    minPrice,
    maxPrice,
    filterAmenities,
    rating,
    allowsCats,
    hasNoPetFees,
    petWeights,
    hotelRating
  })

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const cards = useSelector((state: any) => {
    return state.hotelListReducer.hotels;
  });

  const [oldSearch, setOldSearch] = useState();

  const start = search.checkIn.substring(0, 10)
  const end = search.checkOut.substring(0, 10)

  const date1 = new Date(start).getTime();
  const date2 = new Date(end).getTime();
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

  // NOTE: begin helper and handler functions
    function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
      const R = 6371; // Radius of the earth in km
      const dLat = deg2rad(lat2-lat1);  // deg2rad below
      const dLon = deg2rad(lon2-lon1); 
      const a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
        Math.sin(dLon/2) * Math.sin(dLon/2)
        ; 
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      const d = R * c; // Distance in km
      return d;
    }

    function deg2rad(deg) {
      return deg * (Math.PI/180)
    }

    // console.log(search)

    const calculateCardScore = (km, rating, price, petFee, guestRating, placeTypes = []) => {
      let score = 0;

      //Skip distance ranking if broad location like "San Diego, CA, USA"
      if (!placeTypes.includes('locality')) {
        console.log(placeTypes)
        if (km < 0.4) {
          score = score + 500
        } else if (km < 0.8) {
          score = score + 250
        } else if (km < 1.60934) {
           score = score + 200
         } else if (km < 3.2) {
           score = score + 175
         } else if (km < 8.04) {
           score = score + 150
         } else if (km < 11.26) {
           score = score + 100
         } else if (km < 16.09) {
           score = score + 0
         } else if (km < 32.18) {
           score = score + 0
         }
      } else {
        if (km < 0.4) {
          score = score + 300
        } else if (km < 0.8) {
          score = score + 250
        } else if (km < 1.60934) {
           score = score + 200
         } else if (km < 3.2) {
           score = score + 175
         } else if (km < 8.04) {
           score = score + 150
         } else if (km < 11.26) {
           score = score + 100
         } else if (km < 16.09) {
           score = score + 0
         } else if (km < 32.18) {
           score = score + 0
         }
      }

   
      // if (rating == '4' || rating == '4.5') {
      //   if ((price >= 0 && price <= 350)) {
      //     score = score + 200
      //   } else if (price > 350) {
      //     score = score + 50
      //   }
      // } 
      // if (rating == '3' || rating == '3.5') {
      //   if ((price >= 0 && price <= 250)) {
      //     score = score + 150
      //   } else if (price > 250) {
      //     score = score + 25
      //   }
      // }

      if (rating == '2' || rating == '2.5') {
        score = score - 100
      }
      if (rating == '5' || rating == '5.0') {
        score = score - 100
      }

      // if (guestRating >= 9) {
      //   score = score + 250
      // } else if (guestRating >= 8.5) {
      //   score = score + 200
      // } else if (guestRating >= 7.5) {
      //   score = score + 150
      // } else {
      //   score = score - 200
      // }

  
      // if (petFee === 'NONE' || petFee === 'No Additional Charges' || petFee === '$0.00') {
      //   score = score + 200
      // } else {
      //   const value = parseInt(petFee?.split('.')?.find(item => true)?.slice(1))

      //   if (!value) {
      //     //NaN
      //   } else if (value < 75) {
      //     score = score + 100
      //   } else if (value > 150) {
      //     score = score - 200
      //   } 
      // }

      return score
    }

    const formatHotel = (hotel, tag) => {

      const pricing = hotel?.Packages?.find(item => true)?.SimplePrice || hotel?.SuppliersLowestPackagePrices?.find(item => true)?.Value
      
      let tax = pricing * .145
      // let tax = (.145 || parseFloat(hotel?.taxRate || hotel?.travoTaxRate || 0) * pricing) 
      let priceWithoutTax = (pricing - tax) 
      priceWithoutTax = priceWithoutTax + (priceWithoutTax * .18)      
      tax = tax + (tax * .18)
      const total = priceWithoutTax + tax;

      // const markup = pricing * .17;
      // const total = pricing + markup

      const km = getDistanceFromLatLonInKm(hotel.GeoLocation.Latitude, hotel.GeoLocation.Longitude, search.lat, search.lng)
      const pointValue = calculateCardScore(
        km, 
        hotel.hotelRating, 
        (priceWithoutTax / diffDays),
        // ((pricing - tax) + markup) / diffDays, 
        hotel.petFeeValue, 
        hotel.starRating,
        search?.city?.types
      )
      // console.log(`${hotel.hotelName}: ${pointValue}`)

      // console.log(`Hotel: ${hotel.hotelName} ${pointValue}`)
      const petIntValue = parseInt(hotel?.petFee?.substring(1))


      return {
        pointValue,
        tax,
        isSelect: tag === 'IS_SELECT',
        isRomingoFavorite: tag === 'IS_ROMINGO_FAVORITE',
        isTrending: tag === 'IS_POPULAR',
        imageURLs: hotel.images || [hotel.DefaultImage.FullSize],
        alias: hotel.alias,
        name: hotel.hotelName || '',
        addressLine1: hotel.addressLine,
        city: hotel.city,
        state: hotel.state,
        zipcode: hotel.zipcode,
        petFeePolicy: { maxPets: 0 },
        hotelStarRating: hotel.hotelRating,
        romingoScore: hotel.starRating,
        numberOfReviews: hotel.numberOfReviews,
        lowestAveragePrice: (priceWithoutTax) / diffDays,
        totalPrice: total,
        id: hotel.ID,
        lat: hotel.GeoLocation.Latitude,
        lng: hotel.GeoLocation.Longitude,
        distanceFromSearch: km,
        description: hotel.description,
        pets_allowed: hotel.petsAllowed,
        pet_fee_value: hotel.petFeeValue,
        pet_fee_int: petIntValue,
        pet_fee: hotel.petFee,
        pet_allowance: hotel.petAllowance,
        pet_size: hotel.petSize,
        petBowls: hotel.petBowls,
        petBeds: hotel.petBeds,
        cat_policy: hotel.catPolicy,
        free_parking: hotel.free_parking,
        travolutionaryId: hotel.travolutionaryId,
        amenities: hotel.amenities?.map(amenity => amenity.code) || [],
        pricelineAmenities: hotel.pricelineAmenities?.map(code => code.id) || [],

        //marker
        type: 'hotel',
        label: hotel.DisplayName,
        hotel: {
          pointValue,
          tax,
          isSelect: tag === 'IS_SELECT',
          isRomingoFavorite: tag === 'IS_ROMINGO_FAVORITE',
          isTrending: tag === 'IS_POPULAR',
          imageURLs: hotel.images || [hotel.DefaultImage.FullSize],
          name: hotel.DisplayName,
          alias: hotel.alias,
          addressLine1: hotel.addressLine,
          city: hotel.city,
          state: hotel.state,
          zipcode: hotel.zipcode,
          petFeePolicy: { maxPets: 0 },
          hotelStarRating: hotel.hotelRating,
          romingoScore: hotel.starRating,
          numberOfReviews: hotel.numberOfReview,
          lowestAveragePrice: ((priceWithoutTax) / diffDays),
          totalPrice: (total),
          id: hotel.ID,
          lat: hotel.GeoLocation.Latitude,
          lng: hotel.GeoLocation.Longitude,
          distanceFromSearch: km,
          description: hotel.description,
          pets_allowed: hotel.petsAllowed,
          pet_fee_value: hotel.petFeeValue,
          pet_fee_int: petIntValue,
          pet_fee: hotel.petFee,
          pet_allowance: hotel.petAllowance,
          pet_size: hotel.petSize,
          petBowls: hotel.petBowls,
          petBeds: hotel.petBeds,
          cat_policy: hotel.catPolicy,
          travolutionaryId: hotel.travolutionaryId,
          amenities: hotel.amenities?.map(amenity => amenity.code) || [],
          free_parking: hotel.free_parking,
          pricelineAmenities: hotel.pricelineAmenities?.map(code => code.id) || [],
        }

      }
    }

    function shuffle(array) {
      let currentIndex = array.length,  randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex > 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }

      return array;
    }

    const createTenOf = () => {
      const tags = ['IS_SELECT', 'IS_SELECT', 'IS_SELECT', 'IS_SELECT', 'IS_POPULAR', 'IS_POPULAR', 'IS_ROMINGO_FAVORITE', "NONE", 'NONE', 'NONE']
      const shuffled = shuffle(tags)
      return shuffled
    }

    const loadHotels = () => {
      const hotelsWithTaxRate = hotels.filter(h => h.taxRate);

      const filteredHotels = [];
      const markers = [];
      let min = 0;
      let max = 0;

      const newHotels = hotels.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.ID === value.ID
        ))
      )

      let tags = []

      for (let z = 0; z < newHotels.length; z++) {
        if (z % 10 === 0) {
          tags = createTenOf()
        }
        const tag = tags[(z % 10)]

        const hotel = newHotels[z]
        const restructuredHotel = formatHotel(hotel, tag)
        // console.log(restructuredHotel)
// 
        if (restructuredHotel.totalPrice < min) {
          min = restructuredHotel.totalPrice
        }
        if (restructuredHotel.totalPrice > max) {
          max = restructuredHotel.totalPrice
        }
        filteredHotels.push(restructuredHotel)
        markers.push(restructuredHotel)
      }


      const readyHotels = filteredHotels.sort(function (a, b) {
        const textA = a.name.toUpperCase();
        const textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      })

      min = parseFloat(Math.abs(min).toFixed(2))
      max = parseFloat(Math.abs(max).toFixed(2))

      setMinPrice(min);
      setMaxPrice(max);
      setSliderValue([min, max]);
      setFormatHotels(readyHotels);

      const sorted = sortHotelsBy(readyHotels, sort);
      const finalHotels = sorted.filter((hotel: any) => hotelPetAllowance(hotel));

      let newDisplayHotels = [];
      if(loadingMore || search != oldSearch) {
        newDisplayHotels = finalHotels;
        newDisplayHotels[0].isSelect = true;
        newDisplayHotels[0].isRomingoFavorite = false;
        newDisplayHotels[0].isTrending = false;
      } else {
        const filteredFinalHotels = finalHotels.filter((h: any)=> !displayHotels.find((val: any) => val.id == h.id));
        newDisplayHotels = displayHotels.concat(filteredFinalHotels);
      }

      setOldSearch(search);
      setDisplayHotels(newDisplayHotels);
      setMarkers(markers);
      setDisplayLoader(false);

      dispatch(setList({ hotels: newDisplayHotels, markers }));
    }

    // Util, can be removed and imported from elsewhere
    const compareNumsWithNaN = (
      a: number | typeof NaN,
      b: number | typeof NaN,
      callback: (arg0: number, arg1: number) => number
    ) => {
      const aIsNaN = Number.isNaN(a);
      const bIsNan = Number.isNaN(b);
      if(aIsNaN && bIsNan) {
        return 0;
      } else if (aIsNaN) {
        return 1;
      } else if (bIsNan) {
        return -1;
      } else {
        return callback(a,b);
      }
    }

    const sortHotelsBy = (toSortHotels, type) => {
      if (toSortHotels.length === 0) {
        return []
      }
      const sortableHotels = [...toSortHotels];

      switch (type) {
        case 'alphabetSort': {
          return sortableHotels.sort(function (a, b) {
            const textA = a.name.toUpperCase();
            const textB = b.name.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          })
        }
        case 'priceSort_low_to_high': {
          return sortableHotels.sort((a, b) => compareNumsWithNaN(
            a.lowestAveragePrice, 
            b.lowestAveragePrice, 
            (x: number, y: number) => x - y
          ));
        }
        case 'priceSort_high_to_low': {
          return sortableHotels.sort((a, b) => compareNumsWithNaN(
            a.lowestAveragePrice, 
            b.lowestAveragePrice, 
            (x: number, y: number) => y - x
          ));
        }
        case 'featured': {
          const sortableByDistance = [...sortableHotels];


          const ordered = sortableByDistance.sort((a, b) => a.distanceFromSearch - b.distanceFromSearch)
          const closest = ordered[0]

    
      
          console.log(search?.city?.types?.includes('locality'))
          if (search?.city?.types?.includes('locality')) {
            return [...new Set([
              ...sortableHotels.sort((a, b) => a.lowestAveragePrice - b.lowestAveragePrice).sort((a, b) => a.pointValue - b.pointValue < 0 ? 1 : -1)
            ])]
          }

          //does this remove from back ordered?
          const unique = [...new Set([
            // closest,
            ...sortableHotels.sort((a, b) => a.lowestAveragePrice - b.lowestAveragePrice).sort((a, b) => a.pointValue - b.pointValue < 0 ? 1 : -1)
            // ...fourStars.sort((a, b) => a.lowestAveragePrice - b.lowestAveragePrice).sort((a, b) => b.romingoScore - a.romingoScore).sort((a, b) => a.distanceFromSearch - b.distanceFromSearch),
            // ...threeStars.sort((a, b) => a.lowestAveragePrice - b.lowestAveragePrice).sort((a, b) => b.romingoScore - a.romingoScore).sort((a, b) => a.distanceFromSearch - b.distanceFromSearch),
            // ...ordered.sort((a, b) => a.lowestAveragePrice - b.lowestAveragePrice).sort((a, b) => b.romingoScore - a.romingoScore)
          ])]

          return unique
        }
        case 'highest_rating': {
          return sortableHotels.sort((a, b) => b.romingoScore - a.romingoScore);
        }
        default: {
          return sortableHotels
        }
      }
    }

    const hotelHasAmenities = (list, hotel) => {
 
      const amenities = Object.keys(list).filter(key => list[key])
      let passed = true
      console.log('amenities',amenities)
      for (let i = 0; i < amenities.length; i++) {  
        const amenity = amenities[i]
     
        if (amenity === 'pool') {
          passed = hotel.amenities.some(item => item == 66 || item == 71)
        } else if (amenity === 'airportShuttle') {
          passed = hotel.amenities.some(item => item == 41 || item == 282)
        } else if (amenity === 'parking') {
          passed = hotel.amenities.some(item => item == 68 || item == 42)
        } else if (amenity === 'spa') {
          passed = hotel.amenities.some(item => item == 84)
          console.log('spa', passed)
        } else if(amenity === 'kitchen') {
          passed = hotel.amenities.some(item => item == 262)
        } else if (amenity === 'wifiIncluded') {
          passed = hotel.amenities.some(item => item == 179 || item == 259 || item == 261)
        } else if (amenity === 'restaurant') {
          passed = hotel.amenities.some(item => item == 76)
        } else if (amenity === 'gym') {
          passed = hotel.amenities.some(item => item == 48)
        } else if(amenity === 'cribs') {
          passed = hotel.amenities.some(item => item == 2017)
        } else if (amenity === 'washerAndDryer') {
          passed = hotel.amenities.some(item => item == 168)
        } else if (amenity === 'dryCleaning') { 
          passed = hotel.amenities.some(item => item == 96)
        } else if (amenity === 'wheelchairAccessible') {
          passed = hotel.amenities.some(item => item == 101)
        } else if (amenity === 'smokeFree') {
          passed = hotel.amenities.some(item => item == 312)
        } else if (amenity === 'free_parking' || amenity === 'freeParking') {
          passed = hotel.free_parking === true;
        }else {
          return false
        }

        if (!passed) return false;
      }
      return passed;

    }
    
    const hotelHasPriceLineAmenities = (list, hotel) => {
      const amenities = Object.keys(list).filter(key => list[key]);
      
      for (let i = 0; i < amenities.length; i++) {  
        const amenity = amenities[i];
        let passed = false;
        
        switch (amenity) {
          case 'hourFrontDesk':
            passed = hotel.pricelineAmenities.includes('1daaf8a8-e290-4ae0-bac8-94f679f2a8a4');
            break;
          case 'kitchen':
            passed = hotel.pricelineAmenities.includes('d5baa1af-97cb-44d7-8c50-9266b51bee39') || 
                     hotel.pricelineAmenities.includes('cbf1e949-d66a-490b-bfca-561141117244');
            break;
          case 'wifiIncluded':
            passed = hotel.pricelineAmenities.includes('cf1fce3f-1583-4a37-b5f9-57512284bc53') || 
                     hotel.pricelineAmenities.includes('9a1222ca-cd79-4147-915b-8d2ed10325a5');
            break;
          case 'parking':
            passed = hotel.pricelineAmenities.includes('b9380ff4-5f7f-4108-a652-8cb508192a50') || 
                     hotel.pricelineAmenities.includes('d338b9c0-7a2b-4a01-b040-03b0cb7d7dd4');
            break;
          case 'ATMMachine':
            passed = hotel.pricelineAmenities.includes('a6a718be-f160-4758-ba89-b63674cd1c07');
            break;
          case 'attractionShuttleService':
            passed = hotel.pricelineAmenities.includes('f9be8154-4335-43cf-8df8-d5a50ca4587f');
            break;
          case 'businessCenter':
            passed = hotel.pricelineAmenities.includes('f138706c-dbfd-4929-8fc5-d958465f6082');
            break;
          case 'carRental':
            passed = hotel.pricelineAmenities.includes('08c4f384-02d7-4807-b520-ded1ee44e7cf');
            break;
          case 'coffeeMaker':
            passed = hotel.pricelineAmenities.includes('3901a661-6028-4c46-96d9-ac3106410e11');
            break;
          case 'concierge':
            passed = hotel.pricelineAmenities.includes('47b87ea2-83e7-4d0c-8085-37867e40a92d');
            break;
          case 'hairDryer':
            passed = hotel.pricelineAmenities.includes('4b17af0b-90cb-47c2-af3a-0d94352a4943');
            break;
          case 'iceMachine':
            passed = hotel.pricelineAmenities.includes('cf1079bb-6898-4e80-bd8a-74a727a45d3b');
            break;
          case 'inRoomSafe':
            passed = hotel.pricelineAmenities.includes('8e833b32-f9ab-4009-98cd-2e46cae7ef55');
            break;
          case 'pool':
            passed = hotel.pricelineAmenities.includes('579d9c14-03c2-47d2-97bd-b2967901c1e3') || 
                     hotel.pricelineAmenities.includes('953364a2-ffb8-44f7-9d34-279eae130d0b') || 
                     hotel.pricelineAmenities.includes('6e1fead1-6120-4c5f-bfd6-53616c550fa5') || 
                     hotel.pricelineAmenities.includes('5d9c77ea-dc98-422b-9f09-98607464ea96');
            break;
          case 'laundry':
            passed = hotel.pricelineAmenities.includes('dcab3fc4-4b18-4d8f-8ffc-b5cc629c790d') || 
                     hotel.pricelineAmenities.includes('d38d9458-438e-4231-801b-dbdee4620831');
            break;
          case 'breakfastIncluded':
            passed = hotel.pricelineAmenities.includes('81a25a4c-0d91-4c20-baf7-27b3b4bd700f') ||
                     hotel.pricelineAmenities.includes('1686f9d8-fb52-4971-8d76-7150f1e1f56b') ||
                     hotel.pricelineAmenities.includes('040744fc-9759-454d-964a-ba195a78361e');
            break;
          default:
            return false;
        }
        
        if (!passed) return false;
      }
      
      return true;
    }
    

    const hotelHasWeights = (value, hotel) => {
      const weight = parseInt(`${hotel.pet_size.charAt(0)}${hotel.pet_size.charAt(1)}`)

      switch (value) {
        case '75':
          return weight > 75 || hotel.pet_size === 'Any Size'
        case '50':
          return weight > 25 || hotel.pet_size === 'Any Size'
        case '25':
          return true
        default:
          return true
      }
    }

    const hotelPetAllowance = (hotel) => {
      const string = hotel.pet_allowance || hotel.petAllowance
      if (string === 'Unlimited') {
        return true
      }
      const allowance =  parseInt(`${string.charAt(0)}`)
      return (allowance >= search?.occupants.dogs)
    }

    const handleSearch = (e) => {
      setQuery(e.target.value);
      clearTimeout(timer)
      const newTimer = setTimeout(() => {
        setShouldFilter(!shouldFilter)
      }, 300)
      setTimer(newTimer)
    }

    const handleSort = (e) => {
      setSort(e.target.value)
      const sorted = sortHotelsBy(displayHotels, e.target.value)
      setDisplayHotels(sorted)
    }

    const valuetext = (value: number) => {
      return value;
    }

    const handleSliderChange = (e) => {
      setSliderValue(e.target.value);
    }

    const handleHotelRatingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setHotelRating({
        ...hotelRating,
        [event.target.name]: event.target.checked,
      });
    };

    const handleRatingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      console.log(event.target.value)
      setRating((event.target as HTMLInputElement).value);
    };

    const handleAmenityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilterAmenities({
        ...filterAmenities,
        [event.target.name]: event.target.checked,
      });
    };

    const handleWeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPetWeights((event.target as HTMLInputElement).value);
    };

    const handlePetNumberChange = (number) => {
      dispatch(
        saveSearch({
          ...search,
          occupants: {
            ...search?.occupants,
            dogs: number
          }
        })

      )
    }

    const commitToFilters = (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      setViewFilters(false)
    }

    const handleViewFilters = (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      setViewFilters(true)
      setPreviousFilterState({
        query,
        sliderValue,
        minPrice,
        maxPrice,
        filterAmenities,
        rating,
        allowsCats,
        hasNoPetFees,
        petWeights,
        hotelRating
      })
    }

    const handleCancelFilters = (event: React.ChangeEvent<HTMLInputElement>) => {
      setViewFilters(false)
      setQuery(previousFilterState.query)
      setSliderValue(previousFilterState.sliderValue)
      setMinPrice(previousFilterState.minPrice)
      setMaxPrice(previousFilterState.maxPrice)
      setFilterAmenities(previousFilterState.filterAmenities)
      setRating(previousFilterState.rating)
      setAllowsCats(previousFilterState.allowsCats)
      setHasNoPetFees(previousFilterState.hasNoPetFees)
      setHasLowPetFees(previousFilterState.hasLowPetFees)
      setPetWeights(previousFilterState.petWeights)
      setHotelRating(previousFilterState.hotelRating)
    }

    const hotelGuestRatingValid = (guestRating) => {
      if (rating === 'any') {
        return true
      } else if (rating === '9') {
        return ((parseFloat(guestRating)) >= 9)
      } else if (rating === '8') {
        return ((parseFloat(guestRating)) >= 8)
      } else if (rating === '7') {
        return ((parseFloat(guestRating)) >= 7)
      }
    }
  // NOTE: end helper and handler functions

  useEffect(() => {
    if (!loading && hotels.length > 0) {
      const newHotels = formatHotels.filter(hotel => {
        // console.log('formatted hotel', hotel)
        const starRating = hotel.romingoScore ? hotel.romingoScore.toString().charAt(0) : 0
        const hotelRatingR = hotel.hotelStarRating ? hotel.hotelStarRating.toString().charAt(0) : 0
        
        const hotelRatingsNoneChecked = Object.values(hotelRating).every(value => value === false);
        return (hotel.totalPrice >= sliderValue[0]
          && hotel.totalPrice <= sliderValue[1]
          && hotel.name.toLowerCase().includes(query.toLowerCase())
          && hotelGuestRatingValid(starRating)
          && (hotel.pricelineAmenities && hotel.pricelineAmenities.length > 0 ? hotelHasPriceLineAmenities(filterAmenities, hotel) : hotelHasAmenities(filterAmenities, hotel))
          && (allowsCats ? hotel.cat_policy === 'Yes' : true)
          && (hasNoPetFees ? hotel.pet_fee_value === '$0.00' : true)
          && (hasLowPetFees ? hotel.pet_fee_int <= 50 : true)
          && hotelHasWeights(petWeights, hotel)
          && hotelPetAllowance(hotel)
          && hotelRatingsNoneChecked ? true : hotelRating[hotelRatingR]
        )
      })
      const sorted = sortHotelsBy(newHotels, sort)
      setDisplayHotels(sorted)
      setMarkers(newHotels)
    }
  }, [shouldFilter, filterAmenities, rating, hotelRating, hasNoPetFees, hasLowPetFees, petWeights, allowsCats, search?.occupants?.dogs])


  useEffect(() => {

    if(!loading && hotels?.length) {
      setDisplayLoader(true);   
      loadHotels();
      setShowSearchBar(false);
    }
  }, [hotels, search])

  useEffect(() => {
    sessionId && sessionStorage.setItem('sessionId', sessionId)
  },[sessionId]);

  //This search is outside of USA
  const twoLetterCodes = Object.keys(stateCodes)

  if ( !(search?.city?.description.includes('USA') || search?.city?.description.includes('United States') || twoLetterCodes.find(code => search?.city?.description.includes(code))) ) {
    return (
      <Box sx={{ background: "#feffff" }}>
        <Navbar />

        <Box maxWidth="780px" mx="auto">
          <Typography variant="h5" mb="1rem" mt="4rem">Whoops, sorry we aren&apos;t quite in the destination that you are searching.</Typography>
          <Typography variant="h5">Romingo currently partners with accommodations located in The United States.</Typography>
        </Box>

        {!mobile && 
          <Box mx="auto" maxWidth="820px">
            <LargeFilterBar mapOnPage />
          </Box>
        }

        {mobile && 
          <FilterBar 
            home={false}
            mapOnPage
          />
        }

        <ErrorDog size="320px" />
      </Box>
    )
  }

  if(loading || displayLoader) {
    return <Loader size="400px"/>
  }

  const Banner = () => (
    <Box sx={{
      width: "100%",
      backgroundColor: "#F2EBE4",
      borderRadius: "16px",
      gap: "0.5rem",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: "center",
      px: "0rem",
      py: "0.5rem",
    }}>
      <Box sx={{
             minWidth: { xs: '76px', sm: '76px', md: '76px' },
             display: 'flex',
             alignItems: 'center',
             justifyContent: 'center',
             height: { xs: '80px', sm: '80px' },
             marginLeft: '20px'
           }}>
        <img src={bannerIconTennis} alt="Dogs Icon" style={{ width: '100%', height: '100%', objectFit: 'contain',}} />
      </Box>
           <Box sx={{ 
             display: "flex", 
             flexDirection: "column", 
             color: "text.primary",
             px: "0.5rem",
             flexGrow: 1,
           }}>
             <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
               <Typography variant="body1" component="span" sx={{ fontSize: { xs: '0.75rem', sm: '1rem' }, ml: '10px', width: {md: '100%', sm: '100%'}, maxWidth: '960px' }}>
                 Sign up for Romingo Rewards and start earning free travel.
                 Earn a $100 travel credit towards future travel.
               </Typography>
               
             </Box>
             <Button
                 variant="contained"
                 sx={{
                     display: { xs: 'block', md: 'none'},
                     fontWeight: 800,
                     fontSize: { xs: '0.5rem', sm: '0.75rem' },
                     fontFamily: "sans-serif",
                     width: '100%',
                     ml: '80px',
                     textTransform: 'uppercase',
                     marginLeft: { xs: 'auto', sm: 'auto', md: '0' },
                 }}
                 onClick={() => navigate('/create-account')}
                     >
                 Sign in, it&apos;s free
             </Button>
          </Box>
          <Button
              variant="contained"
              sx={{
                  display: { xs: 'none', md: 'block'},
                  fontWeight: 800,
                  fontSize: { xs: '0.5rem', sm: '0.75rem' },
                  fontFamily: "sans-serif",
                  mr: '20px',
                  minWidth: '160px',
                  textTransform: 'uppercase',
                  marginLeft: { xs: 'auto', sm: 'auto', md: '0' },
              }}
              onClick={() => navigate('/create-account')}
                  >
              Sign in, it&apos;s free
          </Button>
    </Box>
  );
  

  return (
    <Box sx={{ background: "#feffff" }}>
      <Navbar />
      {mobile && 
        <Box>
          {!showSearchBar && 
            <Box 
              sx={{
                borderRadius: 5,
                border: '1px solid black',
                p: '1rem',
                m: '0.5rem',
                my: '1rem',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
              onClick={() => setShowSearchBar(true)}
            >
              <Box>
                <Typography variant="base"><b>{search.city?.description}</b></Typography>
                <Box display="flex" flexDirection="row" gap="2rem">
                  <Typography sx={{ fontSize: '12px'}}>{moment(search.checkIn).format('MMM DD')} - {moment(search.checkOut).format('MMM DD')}</Typography>
                  <Typography sx={{ fontSize: '12px'}}>{search.occupants.adults + search.occupants.children} Guests, {search.occupants.dogs} Pets</Typography>
                </Box>
              </Box>  
              <Box ml="auto">
                <Edit sx={{ fontSize: '18px', color: 'gray' }} />
              </Box>
            </Box>
          }
          {showSearchBar && 
            <Box mb="1rem">
              <FilterBar 
                home={false}
                mapOnPage
              />
              <Button fullWidth onClick={() => setShowSearchBar(false)}>Close</Button>
            </Box>
          }
        </Box>
      }

      <Grid
        container 
        direction='row' 
        justifyContent="center" 
        sx={{ 
          mt: "1rem", 
          px: { xs: 0, sm: 0, md: 0, lg: '6rem'},
        }} 
        style={{ margin: 'auto', position: 'relative', }} 
      >
        {mobile ? (
          <Grid item container justifyContent='space-between' style={{ padding: '0 10px' }}>
            <Button variant="outlined" style={{ width: '48%', marginBottom: 10 }} onClick={() => setOpenMap(true)}>
              View on full map
            </Button>
            <Button variant="outlined" style={{ width: '48%', marginBottom: 10 }} onClick={(e) => handleViewFilters(e)}>
              View filters
            </Button>
          </Grid>
        ) : ( 
          <Grid 
            item 
            xs={0} 
            sm={0} 
            md={2.4}
          >
            <Box sx={styles.sidebarSection}>
              <Box 
                sx={{ 
                  display: "flex",
                  width: '100%',
                  mb: 2
                }}
                onClick={() => setOpenMap(true)}
              >
                <Map 
                  center={{ lat: search.lat, lng: search.lng }}
                  height={250}
                  zoom={11}
                  selectedMarker={0}
                  markers={markers}
                  disabled
                />
              </Box>
              <Button variant="outlined" sx={{ width: '100%' }} onClick={() => setOpenMap(true)}>
                View on full map
              </Button>
            </Box>

            <Box>
              <Divider />
              <Box 
                my="1.5rem"
              >
                <Typography variant="h6" mb="0.5rem">Search by property name</Typography>
                <Input 
                  placeholder="e.g. Hyatt" 
                  disableUnderline={true}
                  variant="filled" 
                  sx={{ border: '1px solid gray', borderRadius: '12px', padding: '0.5rem' }} 
                  fullWidth 
                  value={query} 
                  onChange={handleSearch} 
                  startAdornment={<svg style={{marginRight: '0.5rem'}} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M15.5 14h-.79l-.28-.27a6.5 6.5 0 0 0 1.48-5.34c-.47-2.78-2.79-5-5.59-5.34a6.505 6.505 0 0 0-7.27 7.27c.34 2.8 2.56 5.12 5.34 5.59a6.5 6.5 0 0 0 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0c.41-.41.41-1.08 0-1.49zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5S14 7.01 14 9.5S11.99 14 9.5 14"/></svg>}
                />

              </Box>
              <Divider />
            </Box>

            <Box 
              sx={styles.sidebarSection}
            >
              <Filters 
                search={search}
                handlePetNumberChange={handlePetNumberChange}
                showInfoBox={showInfoBox}
                setShowInfoBox={setShowInfoBox}
                petWeights={petWeights}
                handleWeightChange={handleWeightChange}
                setAllowsCats={setAllowsCats}
                allowsCats={allowsCats}
                setHasNoPetFees={setHasNoPetFees}
                hasNoPetFees={hasNoPetFees}
                filterAmenities={filterAmenities}
                handleAmenityChange={handleAmenityChange}
                sliderValue={sliderValue}
                handleSliderChange={handleSliderChange}
                setShouldFilter={setShouldFilter}
                shouldFilter={shouldFilter}
                valuetext={valuetext}
                minPrice={minPrice}
                maxPrice={maxPrice}
                handleHotelRatingChange={handleHotelRatingChange}
                hotelRating={hotelRating}
                handleRatingChange={handleRatingChange}
                rating={rating}
                setHasLowPetFees={setHasLowPetFees}
                hasLowPetFees={hasLowPetFees}
              />
            </Box>
          </Grid>
        )}
        <Grid 
          item 
          xs={12} 
          sm={12} 
          md={8} 
          sx={{ 
            p: '0.5rem',
            ml: { xs: 0, sm: 0, md: 0, lg: "2.5rem" },
          }}
        >
          <Grid
            item
            container
            direction='row'
            sx={{
              maxWidth: {xs: '650px', md: "760px"},
              mx: {xs: 'auto', md: 0}
            }}
          >
            {!mobile && 
              <Box textAlign="left" maxWidth="780px">
                <LargeFilterBar mapOnPage />
              </Box>
            }
          

            <Grid maxWidth="750px" item container direction='row' justifyContent='space-between' alignItems="center">
              <Grid item>
                <Box mt="0.5rem" sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                  <Typography my="1rem">{displayHotels.length} properties</Typography>
                  {!mobile &&
                    <Chip
                      size="small"
                      label="clear all filters"
                      onDelete={() => {
                        setRating('any')
                        setHotelRating({'0': false, '1': false, '2': false, '3': false, '4': false, '5': false})
                        setQuery('')
                        setSliderValue([minPrice, maxPrice])
                        setShouldFilter(!shouldFilter)
                        setFilterAmenities(initialAmenityFilterState)
                        setHasNoPetFees(false)
                        setHasLowPetFees(false)
                        setAllowsCats(false)
                        setPetWeights(null)

                      }}
                    />
                  }
                  {!mobile &&
                    <Box>
                      {petWeights &&
                        <Chip
                          size="small"
                          label={PET_LABEL_WEIGHT[petWeights]}
                          onDelete={() => setPetWeights(null)}
                        />
                      }
                      {allowsCats &&
                        <Chip
                          size="small"
                          label="Accepts cats"
                          onDelete={() => setAllowsCats(false)}
                        />
                      }
                      {hasNoPetFees &&
                        <Chip
                          size="small"
                          label="$0 pet fees"
                          onDelete={() => setHasNoPetFees(false)}
                        />
                      }
                      {(sliderValue[0] != minPrice || sliderValue[1] != maxPrice) &&
                        <Chip
                          size="small"
                          label="Custom Price Range"
                          onDelete={() => setValue([minPrice, maxPrice])}
                        />
                      }
                      {Object.keys(filterAmenities).map(filter => {
                        if (filterAmenities[filter]) {
                          return (
                            <Chip key={filter} size="small" label={amenityTitle[filter]} onDelete={() => {
                              const object = { ...filterAmenities }
                              object[filter] = false
                              setFilterAmenities(object)
                            }}/>
                          )
                        }
                      })}


                      {rating > 0 &&
                        <Chip
                          size="small"
                          label={`${rating}+ guest rating`}
                          onDelete={() => setRating('any')}
                        />
                      }
                    </Box>
                  }
                </Box>
              </Grid>
              <Grid item >
                <FormControl fullWidth size="small">
                  <Select
                    value={sort}
                    onChange={handleSort}
                    variant='standard'
                  >
                    <MenuItem value={'featured'}>Featured</MenuItem>
                    <MenuItem value={'priceSort_low_to_high'}>Price sort (low to high)</MenuItem>
                    <MenuItem value={'priceSort_high_to_low'}>Price sort (high to low)</MenuItem>
                    <MenuItem value={'highest_rating'}>Highest Rating</MenuItem>
                    <MenuItem value={'alphabetSort'}>Alphabet sort</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              maxWidth: {xs: '650px', md: "760px"},
              mx: {xs: 'auto', md: 0}
            }}
          >
            <CardList cards={displayHotels} sessionId={sessionId} search={search} />
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        fullScreen
        open={openMap}
        onClose={() => setOpenMap(false)}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpenMap(false)}
              aria-label="close"
              size="large">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Full screen map
            </Typography>
          </Toolbar>
        </AppBar>
        <Map 
          center={{ lat: search.lat, lng: search.lng }}
          zoom={11}
          selectedMarker={0}
          markers={markers}
          isFullScreen
        />
      </Dialog>

      <Dialog
        fullScreen
        open={viewFilters}
        onClose={() => setOpenMap(false)}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleCancelFilters()}
              aria-label="close"
              size="large">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Filters
            </Typography>
          </Toolbar>
        </AppBar>
        <Chip
          size="medium"
          sx={{ py: '0.5rem', cursor: 'pointer' }}
          label="clear all filters"
          deleteIcon={<></>}
          onClick={() => {
            setRating('any')
            setQuery('')
            setSliderValue([minPrice, maxPrice])
            setShouldFilter(!shouldFilter)
            setFilterAmenities(initialAmenityFilterState)
            setHasNoPetFees(false)
            setAllowsCats(false)
            setPetWeights('all')
          }}
        />
        <Box style={{ padding: 20 }}>
          <TextField id="outlined-basic" label="Search by property name" variant="outlined" value={query} fullWidth onChange={handleSearch} />
        
          <Filters 
            search={search}
            handlePetNumberChange={handlePetNumberChange}
            showInfoBox={showInfoBox}
            setShowInfoBox={setShowInfoBox}
            petWeights={petWeights}
            handleWeightChange={handleWeightChange}
            setAllowsCats={setAllowsCats}
            allowsCats={allowsCats}
            setHasNoPetFees={setHasNoPetFees}
            hasNoPetFees={hasNoPetFees}
            filterAmenities={filterAmenities}
            handleAmenityChange={handleAmenityChange}
            sliderValue={sliderValue}
            handleSliderChange={handleSliderChange}
            setShouldFilter={setShouldFilter}
            shouldFilter={shouldFilter}
            valuetext={valuetext}
            minPrice={minPrice}
            maxPrice={maxPrice}
            handleHotelRatingChange={handleHotelRatingChange}
            hotelRating={hotelRating}
            handleRatingChange={handleRatingChange}
            rating={rating}
            setHasLowPetFees={setHasLowPetFees}
            hasLowPetFees={hasLowPetFees}
          />

          <Box mt="1rem">
            <Button fullWidth variant="contained" onClick={(e) => commitToFilters(e)} >Update</Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  )
}

export default (ListingPageNew);
