const hotelAmenities = [
    {
        id: "pool",
        label: "Pool"
    },
    {
        id: "airportShuttle",
        label: "Airport shuttle service"
    },
    {
        id: "parking",
        label: "Parking"
    },
    {
        id: "spa",
        label: "Spa"
    },
    {
        id: "kitchen",
        label: "Kitchen"
    },
    {
        id: "wifiIncluded",
        label: "WiFi included"
    },
    {
        id: "restaurant",
        label: "Restaurant"
    },
    {
        id: 'breakfastIncluded',
        label: 'Breakfast included'
    },
    {
        id: 'free_parking',
        label: 'Free parking'
    },
    {
        id: "gym",
        label: "Gym"
    },
    {
        id: "cribs",
        label: "Cribs"
    },
    {
        id: "washerAndDryer",
        label: "Washer and Dryer"
    },
    {
        id: "laundry",
        label: "Laundry"
    },
    {                      
        id: "wheelchairAccessible",
        label: "Wheelchair access"
    },
    {
        id: "smokeFree",
        label: "Smoke-free property"
    },
    {
        id: "hourFrontDesk",
        label: "24 hour front desk"
    },
    {
        id: "ATMMachine",
        label: "ATM machine"
    },
    {
        id: "attractionShuttleService",
        label: "Attraction Shuttle Service"
    },
    {
        id: "businessCenter",
        label: "Business Center"
    },
    {
        id: "carRental",
        label: "Car Rental"
    },
    {
        id: "coffeeMaker",
        label: "Coffee Maker"
    },
    {
        id: "concierge",
        label: "Concierge"
    },
    {
        id: "hairDryer",
        label: "Hair Dryer"
    },
    {
        id: "iceMachine",
        label: "Ice Machine"
    },
    {
        id: "inRoomSafe",
        label: "In Room Safe"
    }
];

const petWeightPolicies = [
    {
        value: "25",
        label: "1-25 lbs",
    },
    {
        value: "50",
        label: "26-75 lbs",
    },
    {
        value: "75",
        label: "75+ lbs",
    }
];

const fiveRatings = ['5', '4', '3', '2', '1'];

export { fiveRatings, petWeightPolicies, hotelAmenities }