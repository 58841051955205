import { Helmet } from "react-helmet";
import { FC, useEffect, useState } from "react";
import { lazy, Suspense } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Dispatch } from "redux";
// import { useWindowSize } from "react-use";
import { useNavigate, Link } from "react-router-dom";
import {
  Box,
  CSSObject,
  Button,
  Typography,
  // Grid,
  Slide,
  TextField,
  Divider,
  useMediaQuery
} from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import CheckIcon from '@mui/icons-material/Check';
import Header from '../../components/Header' //lazy(() => import("../../components/Header"));
const Footer = lazy(() => import("../../components/Footer"));

import ListingCardSquare from "../../components/MobileListingsCardHome";
import ListingCardSkeleton from "../../components/UI/ListingCardSkeleton";

import la from '../../assets/public/images/destinationOne.png'
import ny from '../../assets/public/images/destinationTwo.png'
import miami from '../../assets/public/images/destinationThree.png'

import reviewOne from '../../assets/public/sections-icons/review-one.svg'
import reviewTwo from '../../assets/public/sections-icons/review-two.png'
import reviewThree from '../../assets/public/sections-icons/review-three.svg'
import reviewFour from '../../assets/public/sections-icons/review-four.svg'

const IconList = lazy(() => import('../../components/IconList'));

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { GetHomePageProperty } from "../../constants/constants";
import { gql, useQuery } from "@apollo/client";
// import { DateTime } from "luxon";
// import { randomDate } from "../../tools.js";
import { subscribeToNewsletter, subscribeToMailchimp } from '../../services/endpoints'

// import SectionOneImage from '../../assets/public/sections-icons/iStock-1456209324.jpg';
import SectionTwoImage from '../../assets/public/sections-icons/home-hero.jpg';
import SectionThreeImage from '../../assets/public/sections-icons/homepage-dog.jpg'

const NewsLetter = lazy(() => import("../../components/GetOffers/GetOffers"));


import "./Sticky.css";
import "./HomePage.scss";

interface Props {
  sx?: CSSObject;
  footerMenus: {
    about: {
      text: string;
      link: string;
    }[];
    contact: {
      text: string;
      link: string;
    }[];
    blog: {
      text: string;
      link: string;
    }[];
    sitemap: {
      text: string;
      link: string;
    }[];
  };
}


const HomePage: FC<Props> = () => {
  const navigate = useNavigate();

  const { data: newData, loading, error } = useQuery(gql`${GetHomePageProperty}`, { variables: {} })

  const [showLocations, setShowLocations] = useState(false)
  const [showPetPolicies, setShowPetPolicies] = useState(false)
  const [showFull, setShowFull] = useState(false)
  const [showSticky, setShowSticky] = useState(false)

  /* Method that will fix header after a specific scrollable */
  const isSticky = () => {
    const scrollTop = window.scrollY;
    scrollTop >= 200
      ? setShowSticky(true)
      : setShowSticky(false)
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const mobile = useMediaQuery("(max-width:800px)");
  const locationLinks = [
    { to: "pet-friendly-hotels/los-angeles-california", name: 'Los Angeles' },
    { to: "pet-friendly-hotels/san-francisco-california", name: 'San Francisco' },
    { to: "pet-friendly-hotels/san-diego-california", name: 'San Diego' },
    { to: "pet-friendly-hotels/palm-springs-california", name: 'Palm Springs' },
    { to: "pet-friendly-hotels/austin-texas", name: 'Austin' },
    { to: "pet-friendly-hotels/dallas-texas", name: 'Dallas' },
    { to: "pet-friendly-hotels/houston-texas", name: 'Houston' },
    { to: "pet-friendly-hotels/phoenix-arizona", name: 'Phoenix' },
    { to: "pet-friendly-hotels/scottsdale-arizona", name: 'Scottsdale' },
    { to: "pet-friendly-hotels/tucson-arizona", name: 'Tucson' },
    { to: "pet-friendly-hotels/santa-fe-new-mexico", name: 'Santa Fe' },
    { to: "pet-friendly-hotels/san-antonio-texas", name: 'San Antonio' },
    { to: "pet-friendly-hotels/denver-colorado", name: 'Denver' },
    { to: "pet-friendly-hotels/seattle-washington", name: 'Seattle' },
    { to: "pet-friendly-hotels/portland-oregon", name: 'Portland' },
    { to: "pet-friendly-hotels/salt-lake-city-utah", name: 'Salt Lake City' },

    { to: "pet-friendly-hotels/atlanta-georgia", name: 'Atlanta' },
    { to: "pet-friendly-hotels/baltimore-maryland", name: 'Baltimore' },
    { to: "pet-friendly-hotels/boston-massachusetts", name: 'Boston' },
    { to: "pet-friendly-hotels/charlotte-north-carolina", name: 'Charlotte' },
    { to: "pet-friendly-hotels/chicago-illinois", name: 'Chicago' },
    { to: "pet-friendly-hotels/cleveland-ohio", name: 'Cleveland' },
    { to: "pet-friendly-hotels/detroit-michigan", name: 'Detroit' },
    { to: "pet-friendly-hotels/indianapolis-indiana", name: 'Indianapolis' },
    { to: "pet-friendly-hotels/miami-florida", name: 'Miami' },
    { to: "pet-friendly-hotels/milwaukee-wisconsin", name: 'Milwaukee' },
    { to: "pet-friendly-hotels/minneapolis-minnesota", name: 'Minneapolis' },
    { to: "pet-friendly-hotels/new-orleans-louisiana", name: 'New Orleans' },
    { to: "pet-friendly-hotels/new-york-new-york", name: 'New York City' },
    { to: "pet-friendly-hotels/washington-dc", name: 'Washington D.C.' },
    { to: "pet-friendly-hotels/las-vegas-nevada", name: 'Las Vegas' },

    { to: "pet-friendly-hotels/asheville-north-carolina", name: "Asheville" },
    { to: "pet-friendly-hotels/orlando-florida", name: "Orlando" },
    { to: "pet-friendly-hotels/nashville-tennessee", name: "Nashville" },
    { to: "pet-friendly-hotels/key-west-florida", name: "Key West" },
    { to: "pet-friendly-hotels/st-george-utah", name: "St. George" },
    { to: "pet-friendly-hotels/charleston-south-carolina", name: "Charleston" },
    { to: "pet-friendly-hotels/jacksonville-florida", name: "Jacksonville" },
    { to: "pet-friendly-hotels/colorado-springs-colorado", name: "Colorado Springs" },
    { to: "pet-friendly-hotels/flagstaff-arizona", name: "Flagstaff" },
    { to: "pet-friendly-hotels/sedona-arizona", name: "Sedona" },
    { to: "pet-friendly-hotels/lake-tahoe-california", name: "Lake Tahoe" },
    { to: "pet-friendly-hotels/santa-cruz-california", name: "Santa Cruz" },
    { to: "pet-friendly-hotels/monterey-california", name: "Monterey" },
    { to: "pet-friendly-hotels/santa-barbara-california", name: "Santa Barbara" },
    { to: "pet-friendly-hotels/naples-florida", name: "Naples" },
    { to: "pet-friendly-hotels/reno-nevada", name: "Reno" },
    { to: "pet-friendly-hotels/breckenridge-colorado", name: "Breckenridge" },
    { to: "pet-friendly-hotels/aspen-colorado", name: "Aspen" },
    { to: "pet-friendly-hotels/san-luis-obispo-california", name: "San Luis Obispo" },
    { to: "pet-friendly-hotels/sacramento-california", name: "Sacramento" },
    { to: "pet-friendly-hotels/philadelphia-pennsylvania", name: "Philadelphia" },
    { to: "pet-friendly-hotels/santa-monica-california", name: "Santa Monica" },
    { to: "pet-friendly-hotels/myrtle-beach-south-carolina", name: "Myrtle Beach" },
    { to: "pet-friendly-hotels/st-louis-missouri", name: "St. Louis" },
  
  ]
  locationLinks.sort((a, b) => a.name.localeCompare(b.name))

  const policyLinks = [
    { to: 'marriott-pet-policy', name: "Marriott hotels pet policy" },
    { to: 'hilton-pet-policy', name: "Hilton hotels pet policy" },
    { to: 'hyatt-pet-policy', name: "Hyatt hotels pet policy" },
    { to: "motel-6-pet-policy", name: "Motel 6 hotels pet policy" },
    { to: 'la-quinta-pet-policy', name: "La Quinta Inn pet policy" },
    { to: 'holiday-inn-pet-policy', name: "Holiday Inn pet policy" },
    { to: 'doubletree-pet-policy' , name: "DoubleTree pet policy" },
    { to: 'embassy-suites-pet-policy' , name: "Embassy Suites pet policy" },
    { to: 'best-western-pet-policy' , name: "Best Western pet policy" },
    { to: 'sheraton-pet-policy' , name: "Sheraton pet policy" },
  ]
 

  const HeaderSkeleton = () => (
    <Box
      sx={{
        minHeight: "54x",
        backgroundColor: "#f0f0f0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="h6" sx={{ color: "#ccc" }}></Typography>
    </Box>
  );
  

  const InfoBox = ({ imgSrc, imgAlt, imgWidth, header, text }) => (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '300px', p: '1.5rem', backgroundColor: 'white', borderRadius: '24px', boxShadow: '21px 7px 63px #0000000d' }}>
      <img style={{ width: imgWidth, textAlign: 'center' }} loading="lazy" src={imgSrc} alt={imgAlt} />
      <Box textAlign="center">
        <Typography variant="h5" pb="0.5rem">{header}</Typography>
        <Typography component="p" variant="base">{text}</Typography>
      </Box>
    </Box>
  )

  const RightPhotoBox = ({ imgSrc, imgAlt, backgroundColor, fontColor = 'black', header, text, cta, extraText }) => (
    <Box sx={{ width: '100%', backgroundColor: backgroundColor, display: { xs: 'block', sm: 'block', md: 'flex' }, justifyContent: 'center', alignItems: 'flex-start' }}>
      <Box
        component="img"
        sx={{
          marginLeft: 'auto',
          maxWidth: '100%',
          display: { xs: 'block', sm: 'block', md: 'none' }
        }}
        src={imgSrc}
        alt={imgAlt}
        loading="lazy"
      />
      <Box sx={{ p: { xs: '1.5rem', sm: '1.5rem', md: 0 }, maxWidth: { xs: 'auto', sm: 'auto', md: '440px' }, mx: 'auto', my: !extraText && 'auto' }}>
        <Box
          sx={extraText
            ? { mt: { md: '2rem', lg: '5rem' } }
            : { mt: 'auto' }
          }
        >
          <Typography
            variant="h2"
            sx={{
              color: fontColor,
              textAlign: { xs: 'left', sm: 'left', md: 'left' },
              mt: { xs: '1rem', sm: '1rem', md: 0 },
              mb: extraText ? { xs: '1rem', lg: '1.5rem' } : '1.5rem',
              fontSize: extraText
                ? { xs: '1.25rem', md: '1.25rem', lg: 'auto' }
                : 'auto',
            }}
          >
            {header}
          </Typography>
          <Typography
            variant="p"
            sx={{
              color: fontColor,
              fontSize: extraText
                ? { xs: '1rem', lg: '1.25rem' }
                : 'auto',
              mb: '2rem'
            }}
          >
            {text}
          </Typography>
          <Box
            sx={{
              textAlign: { xs: 'center', sm: 'center', md: 'left' },
              mt: extraText
                ? { xs: '1.2rem', md: '1rem', lg: '2rem' }
                : '2rem',
              mb: extraText
                ? { xs: '1.2rem', md: '1rem', lg: '1.5rem' }
                : '1.5rem',
            }}
          >
            {cta}
          </Box>
        </Box>
        {extraText &&
          <Typography
            variant="body2"
            sx={{
              color: fontColor,
              mt: { xs: '2rem', md: '2.5rem', lg: '5rem' },
              mb: '1rem',
              fontWeight: { xs: 0.1, lg: 1 },
              fontSize: { xs: '0.75rem', md: '0.6rem', lg: '0.8rem' }
            }}
          >
            {extraText}
          </Typography>
        }
      </Box>
      <Box
        component="img"
        sx={{
          marginLeft: 'auto',
          maxWidth: { xs: '100%', sm: '100%', md: '500px', lg: '800px', xl: '800px' },
          display: { xs: 'none', sm: 'none', md: 'block' },
        }}
        src={imgSrc}
        alt={imgAlt}
        loading="lazy"
      />
    </Box>
  )

  const LeftPhotoBox = ({ imgSrc, imgAlt, fontColor = 'black', backgroundColor, header, text, cta, extraText = null }) => (
    <Box sx={{ position: 'relative', backgroundColor: backgroundColor, display: { sm: 'block', md: 'flex' }, alignItems: 'flex-start' }}>
      <Box
        component="img"
        sx={{
          maxWidth: { xs: '100%', sm: '100%', md: '500px', lg: '800px', xl: '800px' }
        }}
        src={imgSrc}
        alt={imgAlt}
        loading="lazy"
      />
      <Box sx={{ p: { xs: '1.5rem', sm: '1.5rem', md: '2rem' }, maxWidth: '500px', mx: 'auto', my: 'auto' }}>
        <Typography variant="h2" sx={{ color: fontColor, textAlign: { xs: 'left', sm: 'left', md: 'left' }, mb: '1.5rem' }}>{header}</Typography>
        <Typography variant="p" sx={{ color: fontColor, mb: '1.5rem' }}>{text}</Typography>
        <Box sx={{ mt: '1.5rem', textAlign: { xs: 'center', sm: 'center', md: 'left' } }}>
          {cta}
        </Box>
        <br />
        <br />
        {extraText && <Typography variant="base" sx={{ color: fontColor }}>{extraText}</Typography>}
      </Box>
    </Box>
  )

  const NewLeftPhotoBox = ({ imgSrc, imgAlt, fontColor = 'black', backgroundColor, header, text, cta, extraText = null }) => (
    <Box sx={{
      position: 'relative',
      backgroundColor: backgroundColor,
      display: { sm: 'block', md: 'flex' },
      alignItems: 'center',
      overflow: 'hidden',
    }}>

      <Box sx={{ display: { xs: 'none', sm: 'none', md: 'grid' }, gridTemplateColumns: 'repeat(2, 1fr)', gap: '1rem', padding: '3rem' }}>
        {imgSrc.map((src, index) => (
          <Box
            key={index}
            component="img"
            sx={{
              borderRadius: '28px',
              width: { sm: 'auto', md: '275px' },
              height: 'auto',

            }}
            src={src}
            alt={imgAlt[index]}
            loading="lazy"
          />
        ))}
      </Box>
      <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' }, p: 0, m: 0, gap: 0 }}>
        {imgSrc.map((src, index) => (<img key={index} loading="lazy" alt={index} src={src} style={{ width: '50%', padding: 0, margin: 0 }} />))}
      </Box>
      <Box sx={{ p: '3rem', flex: '1 1 auto', textAlign: 'center' }}>
        <Typography variant="h2" sx={{ color: fontColor, mb: '1.5rem' }}>{header}</Typography>
        <Typography variant="p" sx={{ color: fontColor, mb: '1.5rem' }}>{text}</Typography>
        <br />
        <Divider sx={{ width: '500px', margin: '40px auto', borderColor: 'black' }} />
        <Box sx={{ textAlign: 'left', fontSize: '25px', }}>
          {cta}
        </Box>
      </Box>
    </Box>
  );

  const TestimonialsSlider = () => {
    const testimonials = [
      {
        quote: "Traveling with my dog Zeus has never been easier.",
        author: "Hannah S.",
        location: "Chapel Hill, NC"
      },
      {
        quote: "We loved bringing our dog Maggie with us on our weekend getaway.",
        author: "Andrea C.",
        location: "San Francisco, CA"
      },
      {
        quote: "It was really easy for us to find a hotel that allowed our 2 doggies.",
        author: "Nick & Rachel T.",
        location: "Grand Rapids, MI"
      }
    ];

    const [activeIndex, setActiveIndex] = useState(0);

    const nextSlide = () => {
      setActiveIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
    };

    useEffect(() => {
      const interval = setInterval(nextSlide, 5000);
      return () => clearInterval(interval);
    }, []);

    return (
      <Box sx={{ position: 'relative', overflow: 'hidden', maxWidth: '750px', margin: '0 auto', textAlign: 'center', height: '275px' }}>
        {testimonials.map((testimonial, index) => (
          <Box key={index} sx={{ display: index === activeIndex ? 'block' : 'none', transitionProperty: 'all', transitionDuration: '0.5s' }}>
            <Typography variant="h2">&quot;{testimonial.quote}&quot;</Typography>
            <Box sx={{ marginTop: '30px' }}>
              <Typography variant="h5">{testimonial.author}</Typography>
              <Typography variant="body2">{testimonial.location}</Typography>
            </Box>
          </Box>
        ))}
        <Box sx={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', background: 'transparent', width: '140px' }}>
          {testimonials.map((_, index) => (
            <span
              key={index}
              onClick={() => setActiveIndex(index)}
              style={{
                cursor: 'pointer',
                display: 'inline-block',
                width: '13px',
                height: '13px',
                borderRadius: '50%',
                background: index === activeIndex ? '#000' : 'rgb(0 0 0 / 30%)',
                margin: '0 15px'
              }}
            />
          ))}
        </Box>
      </Box>
    );
  };

  const SignUpEmail = () => {
    const [isSuccess, setIsSuccess] = useState(false)
    const [email, setEmail] = useState('')
    return (
      <form style={{ marginTop: '1.25rem' }} onSubmit={(e) => {
        e.preventDefault();
        // subscribeToNewsletter(email);
        subscribeToMailchimp(email.trim(), 'New Subscriber')
      }}>
        <TextField
          sx={{
            '& .MuiInput-underline:before': { borderBottomColor: 'white' },
            '& .MuiInput-underline:after': { borderBottomColor: 'white' },
            input: { color: 'black' },
            width: { xs: '100%', sm: '100%', md: '300px' }
          }}
          variant="standard"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target?.value)}
          required
          placeholder='Email address'
          InputProps={{
            endAdornment:
              isSuccess ? <Box sx={{ backgroundColor: 'white', width: '24px', height: '24px', borderRadius: '100%', cursor: 'pointer' }}><CheckIcon /></Box>
                : <Box onClick={() => {
                  setIsSuccess(false)
                  const result = subscribeToMailchimp(email.trim(), 'New Subscriber')
                  setIsSuccess(result.success)
                }} sx={{ backgroundColor: 'white', width: '24px', height: '24px', borderRadius: '100%', cursor: 'pointer' }}><PlayArrowOutlinedIcon /></Box>
          }}
        />
      </form>
    )
  }


  const LocationBox = ({ imageUrl, locationUrl }) => (
    <Link to={locationUrl}>
      <Box
        display="flex"
        flexDirection="column"
        boxShadow={5}
        sx={{
          '&:hover': { boxShadow: 7 },
          alignItems: 'center',
          justifyContent: 'space-between',
          borderRadius: '8px',
          position: 'relative',
          maxWidth: '360px',
          mx: 'auto'
        }}
      >
        <Box
          component="img"
          sx={{
            objectFit: "contain",
            borderRadius: '8px 8px 8px 8px',
            width: '360px'
          }}
          src={imageUrl}
          loading="lazy"
        />
        <Typography
          variant='p'
          sx={{
            textDecoration: 'none',
            textAlign: 'center',
            position: 'absolute',
            bottom: '0',
            left: '0',
            right: '0',
            backgroundColor: '#03989E',
            margin: '10% 20%',
            padding: '15px',
            borderRadius: '50px',
            color: 'white',
            fontWeight: 'bold',
          }}
        >
          Explore
        </Typography>
      </Box>
    </Link>
  )

  const pricesOne = [149, 129, 99]
  const pricesTwo = [175, 189, 199]

  return (
    <div className="homepage">
      <Helmet>
        <title>Romingo: Dog Friendly Hotels & Pet Policy Directory</title>
        <description>Find and book dog-friendly hotels easily with Romingo. Explore a wide range of pet-friendly accommodations, transparent pet policies, and luxury amenities for your furry friends. Plan your next vacation with confidence and ensure a perfect stay for both you and your dog!</description>
        <meta property="og:title" content="Romingo: Dog Friendly Hotels & Pet Policy Directory" />
        <meta property="og:url" content="https://www.romingo.com" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Romingo" />
        <meta name="twitter:title" content="Book pet friendly hotels — Romingo" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      {showSticky && <>
        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
          position="fixed"
          top="31px"
          right="465px"
          zIndex="9999"
        >
          <Box
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            color="blue"
            sx={{
              color: '#03989E',
              cursor: 'pointer',
              '&:hover': {
                color: '#0000A3'
              }

            }}>Book Now</Box>
        </Box>

        <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}
          position="fixed"
          bottom="20px"
          left="25px"
          zIndex="9999"
        >
          <Button
            variant="contained"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          >Book Now</Button>
        </Box>

      </>}

        <Header />
  
      <Box sx={{ background: { xs: '#F2EBE4', sm: '#F2EBE4', md: '#F2EBE4' }, mx: 'auto', py: '1rem', height: { md: 'auto', lg: 'auto' }, }}>
        <Suspense fallback={<div style={{ minHeight: '250px' }}> </div>}>
          <IconList />
        </Suspense>
      </Box>

      <Box onClick={() => setShowLocations(!showLocations)} sx={{ display: { sm: 'block', md: 'none' }, boxShadow: 2, "&:hover": { boxShadow: 3 }, textAlign: 'center', p: '2em', borderRadius: '20px', mx: '1rem', mt: '2rem', mb: '0.25rem' }}>
        <Box sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography sx={{ fontSize: '1.2em' }}>Explore destinations</Typography>
          <KeyboardArrowDownIcon color="primary" />
        </Box>
      </Box>

      <Box onClick={() => setShowPetPolicies(!showPetPolicies)} sx={{ display: { sm: 'block', md: 'none' }, boxShadow: 2, "&:hover": { boxShadow: 3 }, textAlign: 'center', p: '2em', borderRadius: '20px', mx: '1rem', mt: '2rem', mb: '0.25rem' }}>
        <Box sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography sx={{ fontSize: '1.2em' }}>Hotel pet policies</Typography>
          <KeyboardArrowDownIcon color="primary" />
        </Box>
      </Box>


      <Box sx={{
        mt: { xs: '3rem', sm: '3rem', md: "0rem" },
        marginBottom: { sm: 0, md: 0, lg: 0 },
        pb: 0,
        background: 'white',

      }}
      >

        <Box
          sx={{
            mx: { xs: '0.5em', sm: '1em', lg: '0.6em' },
            mb: '2rem',
          }}
        >
          <Box
            sx={{
              mx: 'auto',
              maxWidth: '1200px',
              py: '5rem'
            }}
          >
            <Typography variant="h4" sx={{ mb: '1rem', textAlign: { xs: 'center', md: 'left' } }}>Where to next?</Typography>
            {mobile ? (

              <Carousel
                infiniteLoop={true}
                showStatus={false}
                showIndicators={false}
                showArrows={mobile ? false : true}
                showThumbs={false}
                centerSlidePercentage={50}
                centerMode={mobile ? false : true}
                lazyLoad={true}
                renderArrowPrev={(clickHandler, hasPrev) => (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      top: '45%',
                      left: 0,
                      boxShadow: 2,
                      borderRadius: '100%',
                      position: 'absolute',
                      backgroundColor: 'white',
                      p: '1rem',
                      zIndex: 50,
                    }}
                    onClick={clickHandler}
                  >
                    <ArrowBackIosNewIcon sx={{ width: '20px', height: '20px' }} />
                  </Box>
                )}
                renderArrowNext={(clickHandler, hasPrev) => (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      top: '45%',
                      right: 0,
                      boxShadow: 2,
                      borderRadius: '100%',
                      position: 'absolute',
                      backgroundColor: 'white',
                      p: '1rem',
                      zIndex: 50,
                    }}
                    onClick={clickHandler}
                  >
                    <ArrowForwardIosIcon sx={{ width: '20px', height: '20px' }} />
                  </Box>
                )}
              >
                <LocationBox
                  imageUrl={la}
                  locationUrl="pet-friendly-hotels/los-angeles-california"
                  loading="lazy"
                  sx={{
                    width: '360px',
                    height: '480px',
                    mx: 'auto',
                  }}
                />
                <LocationBox
                  imageUrl={ny}
                  locationUrl="pet-friendly-hotels/new-york-new-york"
                  loading="lazy"
                  sx={{
                    width: '360px',
                    height: '480px',
                    mx: 'auto',
                  }}
                />
                <LocationBox
                  imageUrl={miami}
                  locationUrl="pet-friendly-hotels/miami-florida"
                  loading="lazy"
                  sx={{
                    width: '360px',
                    height: '480px',
                    mx: 'auto',
                  }}
                />
     
              </Carousel>
            ) : (

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: { xs: 'space-between', md: 'space-between' },
                  flexDirection: { xs: 'column', md: 'row' },
                  alignItems: { xs: 'center', md: 'auto' },
                  mb: { xs: 0, sm: 0, md: '0.5rem' },
                  gap: '2rem',
                }}
              >
                <LocationBox
                  imageUrl={la}
                  locationUrl="pet-friendly-hotels/los-angeles-california"
                  loading="lazy"
                />
                <LocationBox
                  imageUrl={ny}
                  locationUrl="pet-friendly-hotels/new-york-new-york"
                  loading="lazy"
                  sx={{
                    width: '360px',
                    height: '480px',
                    mx: 'auto',
                  }}
                />
                <LocationBox
                  imageUrl={miami}
                  locationUrl="pet-friendly-hotels/miami-florida"
                  loading="lazy"
                />
          
              </Box>
            )}
          </Box>
        </Box>
        <Box>
          <RightPhotoBox
            imgSrc={SectionTwoImage}
            imgAlt="romingo rewards"
            backgroundColor="#F2EBE4"
            fontColor="black"
            header={
              <Typography variant="h4" sx={{ mb: '1rem', textAlign: { xs: 'center', md: 'left' } }}>
                Romingo rewards 🐶
              </Typography>
            }
            text="Get rewarded with Romingo! Members earn $100 in free travel credits with every 10 room nights* booked."
            cta={<Button sx={{ width: '300px' }} variant="contained" onClick={() => navigate('/create-account')}>Sign up for free</Button>}
            extraText="*terms: *Members earn a $100 travel voucher for future use when a minimum of 10 qualified room nights are booked and consumed. Minimum spend of $100 per room, per night, including taxes/fees, required."
          />
        </Box>

        <Box sx={{ mt: { xs: '0rem', sm: '0rem', md: '0rem' }, mb: 0, pb: 0 }}>
          <NewLeftPhotoBox
            imgSrc={[reviewOne, reviewTwo, reviewThree, reviewFour]}
            loading="lazy"
            imgAlt="Left Photo"
            backgroundColor="#f1ebe5"
            fontColor="black"
            header={
              <Typography variant="h4" sx={{ mb: '1rem', textAlign: { xs: 'center', md: 'center' } }}>
                Over 25,000 travelers and their pets have experienced pet friendly travel with Romingo
              </Typography>
            }

            text="Roam the world freely with Romingo. With endless tail-wagging adventures, new discoveries, and plenty of treats along the way, Romingo invites you to experience pet friendly travel reimagined."
            cta={<TestimonialsSlider />}
          />
        </Box>



        <Box sx={{ gap: '1.5rem', maxWidth: "1040px", mx: 'auto', mt: { xs: '2rem', sm: '2rem', md: '4rem' }, mb: '2rem' }}>
          <Typography
            variant="h4"
            sx={{
              marginLeft: '0 !important',
              mb: '1rem',
              textAlign: { xs: 'center', md: 'left' },  // Center on mobile, left-align on larger screens
              ml: { xs: 0, sm: 0, md: '0.6em' }  // No margin on mobile, specific margin on larger screens
            }}
          >
            Pet-approved favorites
          </Typography>


          <Carousel
            infiniteLoop={true}
            showStatus={false}
            showIndicators={false}
            showArrows={mobile ? false : true}
            showThumbs={false}
            centerSlidePercentage={mobile ? 100 : 45}  // Show one full slide with half of each on the sides
            centerMode={true}
            lazyLoad={true}
            renderArrowPrev={(clickHandler, hasPrev) => (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  top: '45%',
                  left: 0,
                  boxShadow: 2,
                  borderRadius: '100%',
                  position: 'absolute',
                  backgroundColor: 'white',
                  p: '1rem',
                  zIndex: 50,
                }}
                onClick={clickHandler}
              >
                <ArrowBackIosNewIcon sx={{ width: '20px', height: '20px' }} />
              </Box>
            )}
            renderArrowNext={(clickHandler, hasPrev) => (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  top: '45%',
                  right: 0,
                  boxShadow: 2,
                  borderRadius: '100%',
                  position: 'absolute',
                  backgroundColor: 'white',
                  p: '1rem',
                  zIndex: 50,
                }}
                onClick={clickHandler}
              >
                <ArrowForwardIosIcon sx={{ width: '20px', height: '20px' }} />
              </Box>
            )}
          >
            {loading ? (
              <>
                <Box sx={{ p: '1em', width: { xs: '90%', sm: '90%', md: '500px' }, mx: 'auto' }}>
                  <ListingCardSkeleton key={0} />
                </Box>
                <Box sx={{ p: '1em', width: { xs: '90%', sm: '90%', md: '500px' }, mx: 'auto' }}>
                  <ListingCardSkeleton key={1} />
                </Box>
                <Box sx={{ p: '1em', width: { xs: '90%', sm: '90%', md: '500px' }, mx: 'auto' }}>
                  <ListingCardSkeleton key={2} />
                </Box>
                <Box sx={{ p: '1em', width: { xs: '90%', sm: '90%', md: '500px' }, mx: 'auto' }}>
                  <ListingCardSkeleton key={3} />
                </Box>
                <Box sx={{ p: '1em', width: { xs: '90%', sm: '90%', md: '500px' }, mx: 'auto' }}>
                  <ListingCardSkeleton key={4} />
                </Box>
                <Box sx={{ p: '1em', width: { xs: '90%', sm: '90%', md: '500px' }, mx: 'auto' }}>
                  <ListingCardSkeleton key={5} />
                </Box>
              </>
            ) : (
              newData.getHomepagePropertiesThree.map((hotel, i) => (
                <Box key={i} sx={{ p: '1em', width: { xs: '100%', sm: '90%', md: '450px' }, mx: 'auto' }}>
                  <ListingCardSquare
                    {...hotel}
                    name={hotel.hotelName === 'Margaritaville Resort Palm Springs' ? 'Margitaville Resort' : hotel.hotelName}
                    lowestTotalPriceAfterTax={i < 3 ? pricesOne[i] : pricesTwo[i - 3]}
                    highlighted={false}
                  />
                </Box>
              ))
            )}
          </Carousel>
        </Box>

      </Box>

      <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }} backgroundColor="#ffffff" p="2rem" mt="2rem">
        <Box sx={{
          mx: 'auto', maxWidth: '760px',
          mt: '4rem',
          mb: '8rem',
          p: '1.5rem',
          boxShadow: 2,
          gap: '1rem',
          borderRadius: '20px',
          backgroundColor: 'white',
          height: showFull ? 'auto' : '220px',
          overflow: 'hidden'
        }}>
          <Box>
            <Typography variant="h4" mb="1rem" textAlign="center">Plan your next trip with Romingo</Typography>
          </Box>
          {!showFull && <Box position="relative">
            <Box
              onClick={() => setShowFull(!showFull)}
              sx={{
                textAlign: "center",
                position: "absolute",
                top: "160px",
                backgroundColor: "white",
                width: "100%",
                py: "0.25rem",
                '&:hover': { backgroundColor: '#D3D3D3' }
              }}
            >
              <KeyboardArrowDownIcon color="white" />
            </Box>
          </Box>
          }
          <Box display="flex" flexDirection="row" sx={{ justifyContent: 'space-around' }}>
            <Box display="flex" flexDirection="column" gap="0.5rem">
              <Typography borderBottom="1px solid black" variant="p" fontSize="1.25rem">Explore our destinations</Typography>
              {locationLinks.map(link =>
                <Box key={link.to}>
                  <Link key={link.to} to={link.to}>
                    <Typography variant="base">
                      {link.name} pet-friendly hotels
                    </Typography>
                  </Link>
                </Box>
              )}
            </Box>
            <Box display="flex" flexDirection="column" gap="0.5rem">
              <Typography borderBottom="1px solid black" variant="p" fontSize="1.25rem">Hotel pet policies</Typography>
              {policyLinks.map(link =>
                <Box key={link.to}>
                  <Link key={link.to} to={link.to}>
                    <Typography variant="base">
                      {link.name} pet-friendly hotels
                    </Typography>
                  </Link>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <div id="cta">
        <RightPhotoBox
          imgSrc={SectionThreeImage}
          loading="lazy"
          imgAlt="sign up for romingo exclusive deals"
          backgroundColor="#F2EBE4"
          fontColor="black"
          header={
            <Typography variant="h4" sx={{ mb: '1rem', textAlign: { xs: 'center', md: 'left' } }}>
              Sign up for Romingo exclusive deals and pet-friendly tips
            </Typography>
          }
          text="Get access to discounted rates and last-minute travel deals, plus $20 off your first stay."
          cta={<SignUpEmail />}
        />
      </div>


      <Slide direction='up' in={showLocations} mountOnEnter unmountOnExit>
        <Box sx={{
          position: 'fixed',
          overflow: 'auto',
          bottom: '0',
          height: '80%',
          width: '100%',
          backgroundColor: 'white',
          borderTopRightRadius: '20px',
          borderTopLeftRadius: '20px',
          zIndex: 1000
        }}>
          <Box position="relative" width="90%" textAlign="center" sx={{ m: '1rem', mt: '1.5rem', backgroundColor: 'white', }}>
            <Typography textAlign="center" variant="h5">Explore destinations</Typography>
            <Button sx={{ position: 'absolute', top: -6, right: 0 }} variant="outlined" onClick={() => setShowLocations(false)}>X</Button>
          </Box>
          <Box height="88%" overflow="scroll">
            {locationLinks.map(link =>
              <Link key={link.to} to={link.to} style={{ textDecoration: 'none', color: 'black' }}><Box sx={{ px: '1.25rem', py: '0.75rem', cursor: 'pointer', '&:hover': { backgroundColor: '#d9f7fc' } }}><Typography variant="p">{link.name}</Typography></Box></Link>
            )}
          </Box>
        </Box>
      </Slide>

      <Slide direction='up' in={showPetPolicies} mountOnEnter unmountOnExit>
        <Box sx={{
          position: 'fixed',
          overflow: 'auto',
          bottom: '0',
          height: '80%',
          width: '100%',
          backgroundColor: 'white',
          borderTopRightRadius: '20px',
          borderTopLeftRadius: '20px',
          zIndex: 1000
        }}>
          <Box position="relative" width="90%" textAlign="center" sx={{ m: '1rem', mt: '1.5rem', backgroundColor: 'white', }}>
            <Typography textAlign="center" variant="h5">Hotel pet policies</Typography>
            <Button sx={{ position: 'absolute', top: -6, right: 0 }} variant="outlined" onClick={() => setShowPetPolicies(false)}>X</Button>
          </Box>
          <Box height="88%" overflow="scroll">
            {policyLinks.map(link =>
              <Link key={link.to} to={link.to} style={{ textDecoration: 'none', color: 'black' }}><Box sx={{ px: '1.25rem', py: '0.75rem', cursor: 'pointer', '&:hover': { backgroundColor: '#d9f7fc' } }}><Typography variant="p">{link.name}</Typography></Box></Link>
            )}
          </Box>
        </Box>
      </Slide>


      <Suspense fallback={<div>Loading footer...</div>}>
        <Footer />
      </Suspense>
      <Suspense fallback={<div>Loading newsletter...</div>}>
        <NewsLetter />
      </Suspense>

    </div>
  );
};

export default HomePage;
