export const SAVE_SEARCH = "SAVE_SEARCH";
export const REMOVE_SEARCH = "REMOVE_SEARCH";
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_USER = "SET_USER";
export const SET_LIST = "SET_LIST";
export const SET_DETAILS = "SET_DETAILS";
export const SET_CHECKOUT = "SET_CHECKOUT";
export const SET_CITIES = "SET_CITIES";
export const SET_VIEW_METHOD = "SET_VIEW_METHOD";
