import * as React from "react";
import { Person } from "@mui/icons-material";
import {
  Box,
  Menu,
  MenuItem,
  Link,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  useMediaQuery,
  DialogActions,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authService } from "../services/authService.js";
import favicon from "../assets/images/favicon.png";
import { logoutUser } from "../store/userReducer";

export default function BasicMenu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLargeScreen = useMediaQuery("(min-width: 1200px)");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showPopup, setShowPopup] = React.useState(false);
  const [showDeleteAccountDialog, setShowDeleteAccountDialog] =
    React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignInClick = () => {
    setAnchorEl(null);
    setShowPopup(true);
  };

  const closeDialog = () => {
    setShowPopup(false);
  };

  const logout = () => {
    setAnchorEl(null);
    dispatch(logoutUser());
    navigate("/");
  };

  const handleCancel = () => {
    setShowDeleteAccountDialog(false);
    handleClose();
  };

  const deleteAccount = async () => {
    setShowDeleteAccountDialog(false);
    handleClose();
    const result = await fetch(
      `${process.env.REACT_APP_BASE_ENDPOINT}v2/user/${
        authService.getUser().id
      }`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "no-cors",
      }
    );
    logout();
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Person sx={{ fontSize: "24px", color: "black" }} />
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {!authService.getUser()
          ? [
              <Link
                key="create-account"
                href="/create-account"
                sx={{ textDecoration: "none" }}
              >
                <MenuItem onClick={handleClose}>Create account</MenuItem>
              </Link>,
              isLargeScreen ? (
                <Link
                  key="sign-in-large"
                  href="/login"
                  sx={{ textDecoration: "none" }}
                >
                  <MenuItem onClick={handleClose}>Sign in</MenuItem>
                </Link>
              ) : (
                <MenuItem
                  key="sign-in-small"
                  onClick={handleSignInClick}
                  sx={{ color: "#03989E" }}
                >
                  Sign in
                </MenuItem>
              ),
            ]
          : [
              <Link key="dashboard" href='/profile' sx={{ textDecoration: "none" }}>
                <MenuItem>
                  Dashboard
                </MenuItem>
              </Link>,
              <Link key="logout" sx={{ textDecoration: "none" }}>
                <MenuItem onClick={() => logout()}>Logout</MenuItem>
              </Link>,
              // <Link key="delete-account" sx={{ textDecoration: "none" }}>
              //   <MenuItem onClick={() => setShowDeleteAccountDialog(true)}>
              //     Delete account
              //   </MenuItem>
              // </Link>,
            ]}
      </Menu>

      <Dialog
        open={showDeleteAccountDialog}
        onClose={() => setShowDeleteAccountDialog(false)}
        aria-labelledby="delete-account-dialog-title"
        aria-describedby="delete-account-dialog-description"
      >
        <DialogTitle id="delete-account-dialog-title">
          {"Delete Your Account?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-account-dialog-description">
            Are you sure you want to delete your account? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCancel()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => deleteAccount()} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showPopup}
        keepMounted
        fullWidth
        onClose={closeDialog}
        aria-labelledby="rewards-dialog-title"
        aria-describedby="rewards-dialog-description"
        sx={{
          "& .MuiDialog-paper": { maxWidth: "350px" },
        }}
      >
        <DialogTitle
          id="rewards-dialog-title"
          sx={{
            textAlign: "center",
            fontWeight: 800,
            padding: "8px 24px",
          }}
        ></DialogTitle>
        <DialogContent
          sx={{
            textAlign: "center",
            p: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              mb: 1,
            }}
          >
            <Box
              component="img"
              src={favicon}
              alt="Logo"
              draggable="false"
              sx={{
                borderRadius: "50%",
                width: 60,
                height: 60,
                mr: 2,
              }}
            />
            <Typography
              sx={{
                color: "text.primary",
                fontSize: "17px",
                fontWeight: 800,
                textAlign: "left",
              }}
            >
              Sign up for Romingo Rewards and start earning free travel!
            </Typography>
          </Box>
          <Typography
            sx={{
              color: "text.primary",
              mt: "0.4rem",
            }}
          >
            Book 10 Qualifying Room Nights at Romingo Hotels and earn $100 in
            Romingo Rewards towards free travel.
          </Typography>
          <Button
            href="/login"
            variant="contained"
            sx={{
              mt: 2,
              bgcolor: "#03989e",
              "&:hover": {
                bgcolor: "#038888",
              },
            }}
          >
            Sign in, it&apos;s free
          </Button>
          <Typography
            component="a"
            href="/reward"
            sx={{
              color: "text.primary",
              fontSize: "16px",
              mt: "1rem",
              fontWeight: 800,
              fontFamily: "sans-serif",
              display: "block",
            }}
          >
            Learn about Romingo Rewards
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
}
