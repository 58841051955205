
const santaFeSearch = {
  "city": {
    "description": "Santa Fe, NM, USA",
    "matched_substrings": [
        {
            "length": 8,
            "offset": 0
        }
    ],
    "place_id": "ChIJqVKY50NQGIcRQN-I_XMjkIw",
    "reference": "ChIJqVKY50NQGIcRQN-I_XMjkIw",
    "structured_formatting": {
        "main_text": "Santa Fe",
        "main_text_matched_substrings": [
            {
                "length": 8,
                "offset": 0
            }
        ],
        "secondary_text": "NM, USA"
    },
    "terms": [
        {
            "offset": 0,
            "value": "Santa Fe"
        },
        {
            "offset": 10,
            "value": "NM"
        },
        {
            "offset": 14,
            "value": "USA"
        }
    ],
    "types": [
        "locality",
        "political",
        "geocode"
    ]
  },
  "lat": 35.6869752,
  "lng": -105.937799
}

const portlandSearch = {
  "city": {
    "description": "Portland, OR, USA",
    "matched_substrings": [
        {
            "length": 8,
            "offset": 0
        }
    ],
    "place_id": "ChIJJ3SpfQsLlVQRkYXR9ua5Nhw",
    "reference": "ChIJJ3SpfQsLlVQRkYXR9ua5Nhw",
    "structured_formatting": {
        "main_text": "Portland",
        "main_text_matched_substrings": [
            {
                "length": 8,
                "offset": 0
            }
        ],
        "secondary_text": "OR, USA"
    },
    "terms": [
        {
            "offset": 0,
            "value": "Portland"
        },
        {
            "offset": 10,
            "value": "OR"
        },
        {
            "offset": 14,
            "value": "USA"
        }
    ],
    "types": [
        "locality",
        "political",
        "geocode"
    ]
  },
  "lat": 45.515232,
  "lng": -122.6783853
}

const newYorkSearch = {
  "city": {
    "description": "New York, NY, USA",
    "matched_substrings": [
        {
            "length": 8,
            "offset": 0
        }
    ],
    "place_id": "ChIJOwg_06VPwokRYv534QaPC8g",
    "reference": "ChIJOwg_06VPwokRYv534QaPC8g",
    "structured_formatting": {
        "main_text": "New York",
        "main_text_matched_substrings": [
            {
                "length": 8,
                "offset": 0
            }
        ],
        "secondary_text": "NY, USA"
    },
    "terms": [
        {
            "offset": 0,
            "value": "New York"
        },
        {
            "offset": 10,
            "value": "NY"
        },
        {
            "offset": 14,
            "value": "USA"
        }
    ],
    "types": [
        "locality",
        "political",
        "geocode"
    ]
  },
  "lat": 40.7127753,
  "lng": -74.0059728
}


const chicagoSearch = {
  "city": {
    "description": "Chicago, IL, USA",
    "matched_substrings": [
        {
            "length": 7,
            "offset": 0
        }
    ],
    "place_id": "ChIJ7cv00DwsDogRAMDACa2m4K8",
    "reference": "ChIJ7cv00DwsDogRAMDACa2m4K8",
    "structured_formatting": {
        "main_text": "Chicago",
        "main_text_matched_substrings": [
            {
                "length": 7,
                "offset": 0
            }
        ],
        "secondary_text": "IL, USA"
    },
    "terms": [
        {
            "offset": 0,
            "value": "Chicago"
        },
        {
            "offset": 9,
            "value": "IL"
        },
        {
            "offset": 13,
            "value": "USA"
        }
    ],
    "types": [
        "locality",
        "political",
        "geocode"
    ]
  },
  "lat": 41.8781136,
  "lng": -87.6297982
}

const austinSearch = {
  "city": {
    "description": "Austin, Texas, USA",
    "matched_substrings": [
        {
            "length": 6,
            "offset": 0
        },
        {
            "length": 2,
            "offset": 8
        }
    ],
    "place_id": "ChIJLwPMoJm1RIYRetVp1EtGm10",
    "reference": "ChIJLwPMoJm1RIYRetVp1EtGm10",
    "structured_formatting": {
        "main_text": "Austin",
        "main_text_matched_substrings": [
            {
                "length": 6,
                "offset": 0
            }
        ],
        "secondary_text": "Texas, USA",
        "secondary_text_matched_substrings": [
            {
                "length": 2,
                "offset": 0
            }
        ]
    },
    "terms": [
        {
            "offset": 0,
            "value": "Austin"
        },
        {
            "offset": 8,
            "value": "Texas"
        },
        {
            "offset": 15,
            "value": "USA"
        }
    ],
    "types": [
        "locality",
        "political",
        "geocode"
    ]
  },
  "lat": 30.267153,
  "lng": -97.7430608
}

const losAngelesSearch = {
  "city": {
    "description": "Los Angeles, CA, USA",
    "matched_substrings": [
        {
            "length": 11,
            "offset": 0
        }
    ],
    "place_id": "ChIJE9on3F3HwoAR9AhGJW_fL-I",
    "reference": "ChIJE9on3F3HwoAR9AhGJW_fL-I",
    "structured_formatting": {
        "main_text": "Los Angeles",
        "main_text_matched_substrings": [
            {
                "length": 11,
                "offset": 0
            }
        ],
        "secondary_text": "CA, USA"
    },
    "terms": [
        {
            "offset": 0,
            "value": "Los Angeles"
        },
        {
            "offset": 13,
            "value": "CA"
        },
        {
            "offset": 17,
            "value": "USA"
        }
    ],
    "types": [
        "locality",
        "political",
        "geocode"
    ]
  },
  "lat": 34.0522342,
  "lng": -118.2436849
}

const denverSearch = {
  "city": {
    "description": "Denver, CO, USA",
    "matched_substrings": [
        {
            "length": 6,
            "offset": 0
        }
    ],
    "place_id": "ChIJzxcfI6qAa4cR1jaKJ_j0jhE",
    "reference": "ChIJzxcfI6qAa4cR1jaKJ_j0jhE",
    "structured_formatting": {
        "main_text": "Denver",
        "main_text_matched_substrings": [
            {
                "length": 6,
                "offset": 0
            }
        ],
        "secondary_text": "CO, USA"
    },
    "terms": [
        {
            "offset": 0,
            "value": "Denver"
        },
        {
            "offset": 8,
            "value": "CO"
        },
        {
            "offset": 12,
            "value": "USA"
        }
    ],
    "types": [
        "locality",
        "political",
        "geocode"
    ]
  },
  "lat": 39.7392358,
  "lng": -104.990251
}

const seattleSearch = {
  "city": {
    "description": "Seattle, WA, USA",
    "matched_substrings": [
        {
            "length": 7,
            "offset": 0
        }
    ],
    "place_id": "ChIJVTPokywQkFQRmtVEaUZlJRA",
    "reference": "ChIJVTPokywQkFQRmtVEaUZlJRA",
    "structured_formatting": {
        "main_text": "Seattle",
        "main_text_matched_substrings": [
            {
                "length": 7,
                "offset": 0
            }
        ],
        "secondary_text": "WA, USA"
    },
    "terms": [
        {
            "offset": 0,
            "value": "Seattle"
        },
        {
            "offset": 9,
            "value": "WA"
        },
        {
            "offset": 13,
            "value": "USA"
        }
    ],
    "types": [
        "locality",
        "political",
        "geocode"
    ]
  },
  "lat": 47.6062095,
  "lng": -122.3320708
}

const sanFranciscoSearch = {
  "city": {
    "description": "San Francisco, CA, USA",
    "matched_substrings": [
        {
            "length": 8,
            "offset": 0
        }
    ],
    "place_id": "ChIJIQBpAG2ahYAR_6128GcTUEo",
    "reference": "ChIJIQBpAG2ahYAR_6128GcTUEo",
    "structured_formatting": {
        "main_text": "San Francisco",
        "main_text_matched_substrings": [
            {
                "length": 8,
                "offset": 0
            }
        ],
        "secondary_text": "CA, USA"
    },
    "terms": [
        {
            "offset": 0,
            "value": "San Francisco"
        },
        {
            "offset": 15,
            "value": "CA"
        },
        {
            "offset": 19,
            "value": "USA"
        }
    ],
    "types": [
        "locality",
        "political",
        "geocode"
    ]
},
"lat": 37.7749295,
"lng": -122.4194155
}

const sanDiegoSearch = {
  "city": {
    "description": "San Diego, CA, USA",
    "matched_substrings": [
        {
            "length": 5,
            "offset": 0
        }
    ],
    "place_id": "ChIJSx6SrQ9T2YARed8V_f0hOg0",
    "reference": "ChIJSx6SrQ9T2YARed8V_f0hOg0",
    "structured_formatting": {
        "main_text": "San Diego",
        "main_text_matched_substrings": [
            {
                "length": 5,
                "offset": 0
            }
        ],
        "secondary_text": "CA, USA"
    },
    "terms": [
        {
            "offset": 0,
            "value": "San Diego"
        },
        {
            "offset": 11,
            "value": "CA"
        },
        {
            "offset": 15,
            "value": "USA"
        }
    ],
    "types": [
        "locality",
        "political",
        "geocode"
    ]
  },
  "lat": 32.715738,
  "lng": -117.1610838
}


export const ADS_LOCATION_SEARCH = {
	SAN_DIEGO: sanDiegoSearch,
	SAN_FRANCISCO: sanFranciscoSearch,
	SEATTLE: seattleSearch,
	DENVER: denverSearch,
	LOS_ANGELES: losAngelesSearch,
	AUSTIN: austinSearch,
	CHICAGO: chicagoSearch,
	NEW_YORK: newYorkSearch,
	PORTLAND: portlandSearch,
	SANTA_FE: santaFeSearch
}



