import React, { FC, useEffect } from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import { gql, useLazyQuery } from "@apollo/client";
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from "../components/Navbar";
import SectionThreeImage from '../assets/public/sections-icons/homepage-dog.jpg';
import { GetReservationDetails } from '../constants/constants';

interface Props {}

const CancellationPage: FC<Props> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const emailAddress = location.state?.emailAddress || new URLSearchParams(location.search).get('email');
  const confirmationNumber = location.state?.confirmationNumber || new URLSearchParams(location.search).get('id');

  const [handleFindReservation, { called, loading, data, error }] = useLazyQuery(gql`${GetReservationDetails}`, {
    variables: { email: emailAddress, propertyConfirmationId: confirmationNumber },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    handleFindReservation();
  }, []);

  const headerStyle = {
    fontSize: '1.5em',
    textAlign: "center"
  };

  return (
    <Box>
      <Navbar />
      <Box sx={{ mx: 'auto', maxWidth: '660px' }}>
        {data?.getReservationDetails?.response?.map((reservation: any, index: number) => (
          <Grid key={index} sx={{
            boxShadow: 3,
            transition: "all .25s ease-in-out",
            "&:hover": { boxShadow: 5 },
            borderRadius: "12px",
            border: "1px solid #ddd",
            p: "1rem",
            mt: { xs: 5 }
          }} container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography sx={headerStyle}>Your Reservation: {reservation?.bookingId} has been Cancelled.</Typography>
            </Grid>
            <Grid sx={{ mt: '30px' }}>
              <Box component="img" sx={{
                marginLeft: 'auto',
                maxWidth: '100%',
                display: { xs: 'none', sm: 'none', md: 'block' },
              }} src={SectionThreeImage} alt='' />
            </Grid>
            {(data?.getReservationDetails?.response[0].isRefundable && data?.getReservationDetails?.response[0].isPaid) && 
              <Grid item xs={12} md={12}>
                <Typography>We&apos;re processing your refund of ${parseFloat(reservation.bookingPrice).toFixed(2)}. Please wait 5-7 business days for processing.</Typography>
              </Grid>
            }
            <Grid sx={{
              display: "flex",
              justifyContent: "center",
              mt: { xs: '20px' }
            }} xs={12} md={12}>
                <Button variant="contained" color="primary" onClick={() => navigate('/')} sx={{ mr: 2 }}>
                    Home
                </Button>
                <Button variant="contained" color="primary" onClick={() => navigate('/listings')}>
                    Book a hotel
                </Button>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Box>
  );
};

export default CancellationPage;
